export default class FileModel {
    constructor() {
        this.alt = '';
        this.title = '';
        this.folder = '';
        this.job = 0;
        this.file = null;
    }

    getAlt() {
        return this.alt;
    }

    setAlt(alt) {
        this.alt = alt;
    }

    getTitle() {
        return this.title;
    }

    setTitle(title) {
        this.title = title;
    }

    getJob() {
        return this.job;
    }

    setJob(job) {
        this.job = job;
    }

    getFolder() {
        return this.folder;
    }

    setFolder(folder) {
        this.folder = folder;
    }

    getFile() {
        return this.file;
    }

    setFile(file) {
        this.file = file;
    }
}
