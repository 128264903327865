export default class BooleanAutoform {
    #state
    constructor(parameter) {
        this.#state = {
            mandatory : false,
            modale : parameter.modale,
            parameter : parameter
        }
        if (this.#state.keyform) {
            this.#state.mandatory = true
        }
    };

    check() {
        var checkValue = true
        var returnTable = []
        var arrayInput = {}
        var errorCode = ""
        var className = "." +  this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData
        var input = document.querySelector(className)
        if (input.value !== true && input.value !== false) {
            checkValue = false
        }
        arrayInput[input.name] = input.value

        returnTable.push(checkValue,input.name,input.value,errorCode)

        return returnTable
    }

    render() {
        return(
            <div className={`banner ${ this.#state.modale == true ? "form-group" : ""}`}>
                <label>{'label-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData}</label>
                <input required={this.#state.mandatory === true ? 'required' : "false"} placeholder={'placeholder-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData} name={this.#state.parameter.parameterData} className={this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData + " form-control"} id={this.#state.parameter.parameteterClass} type="number"/>
            </div>
        )
    }
};

