import React from "react";
import Autoform from "../Autoform/Autoform";
import SettingService from "../Service/Bll/SettingService";
import FileModel from "../Model/FileModel";
import UsersService from "../Service/Bll/UsersService";
import PropTypes from "prop-types";
import {LogoComponent} from "../../App/components/LogoComponent";
import '../css/import.css'
import {FormattedMessage} from "react-intl";
import CacheExtension from "../Filter/CacheExtension";

var width = '12'

export const ImportComponent = (props) => {
    if (props.width){
        width = props.width
    }

    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    var id = CreateGuid()

    return (
        <>
            <div className="row mb-3 mt-3">
                <div className={props.className ? props.className : "col-12 col-lg-6 col-xl-4 mt-3 mb-3"}>
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col-8 pr-0">
                                    <h5 className="m-0 mb-2">
                                         {props.formName}
                                        <small style={{fontSize: "65%", marginLeft: 8}}>
                                            <i></i>
                                        </small>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div>

                                { (props.form) ? (
                                    <form
                                        method="post"
                                        encType="multipart/form-data"
                                        id={id}
                                        className="uploadComponent">
                                        <div className="row">
                                            <input
                                                id="availableResortUploader_folder"
                                                type="hidden"
                                                defaultValue="f_leaveresorts"
                                                name="folder"
                                            />
                                            <input
                                                id="availableResortUploader_job"
                                                type="hidden"
                                                defaultValue={0}
                                                name="job"
                                            />
                                            <input
                                                id={'file_'+props.formName}
                                                type="file"
                                                name="file"
                                                onChange={(event) => submit(event,id,props.action)}
                                                defaultValue=""
                                            />
                                            <label htmlFor={'file_'+props.formName}>
                                                <div className="row justify-content-center">
                                                    <i className="fas fa-2x fa-upload mt-1 mb-1"/>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <strong>{CacheExtension.getDataFromCache("SelectFile")}</strong>
                                                </div>
                                            </label>
                                        </div>
                                    </form>
                                ) : (
                                    <div className={'uploadComponent'}>
                                        <div className="row">
                                            <input
                                                id="availableResortUploader_folder"
                                                type="hidden"
                                                defaultValue="f_leaveresorts"
                                                name="folder"
                                            />
                                            <input
                                                id="availableResortUploader_job"
                                                type="hidden"
                                                defaultValue={0}
                                                name="job"
                                            />
                                            <input
                                                id={'file_'+props.formName}
                                                type="file"
                                                name="file"
                                                defaultValue=""
                                            />
                                            <label htmlFor={'file_'+props.formName}>
                                                <div className="row justify-content-center">
                                                    <i className="fas fa-2x fa-upload mt-1 mb-1"/>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <strong>{CacheExtension.getDataFromCache("SelectFile")}</strong>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                )
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

async function  submit(event,id,action) {
    $("body").css("cursor", "wait");
    event.preventDefault();
    event.stopPropagation();
    var fileModel = new FileModel()
    var file = $('#'+ id).find('input[type="file"]')[0].files[0];
    if (file) {
        fileModel.title = file.name
        fileModel.file = file
        await action(fileModel);
        $("body").css("cursor", "default");
        window.location.reload()
    }
}

LogoComponent.prototype= {
    formName : PropTypes.string,
    className : PropTypes.string,
    action : PropTypes.func,
    form : PropTypes.bool,
}
