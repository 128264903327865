import { Link, Navigate, useParams } from "react-router-dom";
import React, { useEffect, useState,} from "react";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import '../../styles/productDescription.css';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";


export default function ProductDescriptionPage() {
  var service = new ProductService()

  let { gtin, serial } = useParams()

  const [data, setData] = useState(null);
  const [generic, setGeneric] = useState(null);
  const [technic, setTechnic] = useState(null);

  useEffect(() => {
    service.read(gtin, serial).then(function (result) {
      let res = result.getData();

      setData(res);
      const genericKeys = ["hasDiamonds", "braceletSize", "dimension", "movement"];

      const technicKeys = [
        "boxMaterial",
        "boxDescription",
        "boxFloor",
        "boxDetail",
        "boxHeight",
        "movementDetail",
        "waterproof",
        "hasDate",
        "hasBigDate",
        "hasGMT",
        "hasChrono",
        "hasMoonPhase"
      ];

      let entries = Object.entries(res.model);
      let genericArr = []
      let technicArr = []
      for (let [key, value] of entries) {
        if (genericKeys.includes(key)) {
          genericArr.push({ key: key, value: value });
        } else if (technicKeys.includes(key)) {
          technicArr.push({ key: key, value: value });
        }
      }
      setGeneric(genericArr)
      setTechnic(technicArr)
    })
  },[])

  if (!HelperService.isLogged()) {
    return (  <Navigate to="/login" replace={true} />)
  }

  return (
      <>
          <AnimatePresence>
            <m.div key="container__animation"
                   className="container__animation"
                   initial={{width:'100vw' , marginLeft : '100vw' }}
                   animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                   exit={{ marginLeft : '100vw', x: "inherit" }}
                   transition={{duration: 0.30, ease: "linear"}}>
              {data &&
                  <>
                    <div id="header" className={'titleHead'}>
                      <Link className="backLink" id="productDescriptionBack" to={ `/product/${gtin}/${serial}` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                      <h1 id="productDescriptionTitle">{CacheExtension.getDataFromCache("productDescription.title")}</h1>
                    </div>
                    <div id="content">
                      <span className={"py-4"} dangerouslySetInnerHTML={{ __html: data.model.edito }}></span>
                      <div id="productDescriptionGenericBlock" >
                        <span id="productDescriptionGenericTitle">
                          {CacheExtension.getDataFromCache("productDescription.generic")}
                        </span>
                        { generic.map(attribute =>
                            attribute.value !== '' && attribute.value !== 0 &&
                            <>
                              <span id="productDescriptionGenericKey">
                                {CacheExtension.getDataFromCache("product.key." + attribute.key)}
                              </span>
                              {isNaN(attribute.value) ?
                                  <span id="productDescriptionGenericValue" dangerouslySetInnerHTML={{ __html: attribute.value }}></span> :
                                  <span id="productDescriptionGenericValue">{attribute.value === 1 ? CacheExtension.getDataFromCache("Yes") : attribute.value}</span>
                              }
                            </>
                        )}
                      </div>
                      {Object.keys(data.model.optionsJson).length !== 0 &&
                          <div id="productDescriptionOptionsBlock" >
                          <span id="productDescriptionOptionsTitle">
                            Options
                          </span>
                            {Object.entries(data.model.optionsJson).map(([key, value]) =>
                                value !== '' && value !== 0 &&
                                <>
                                  <span id="productDescriptionGenericKey">
                                    {key}
                                  </span>
                                  {isNaN(value) ?
                                      <span id="productDescriptionGenericValue" dangerouslySetInnerHTML={{ __html: value }}></span> :
                                      <span id="productDescriptionGenericValue">{value === 1 ? CacheExtension.getDataFromCache("Yes") : value}</span>
                                  }
                                </>
                            )}
                          </div>
                      }
                      <div id="productDescriptionTechnicBlock">
                  <span id="productDescriptionTechnicTitle">
                    {CacheExtension.getDataFromCache("productDescription.technic")}
                  </span>
                        { technic.map(attribute =>
                            attribute.value !== '' && attribute.value !== 0 &&
                            <>
                              <span id="productDescriptionTechnicKey">
                                {CacheExtension.getDataFromCache("product.key." + attribute.key)}
                              </span>
                              {isNaN(attribute.value) ?
                                  <span id="productDescriptionTechnicValue" dangerouslySetInnerHTML={{ __html: attribute.value }}></span> :
                                  <span id="productDescriptionTechnicValue">{attribute.value === 1 ? CacheExtension.getDataFromCache("Yes") : attribute.value}</span>
                              }
                            </>
                        )}
                      </div>
                    </div>
                  </>
              }
            </m.div>
            <Footer selected="wallet"/>
          </AnimatePresence>
      </>
  );
}