import { Link, Navigate, useNavigate} from "react-router-dom";
import { Footer } from "../components/footerComponent";
import { ReactComponent as ChevronIcon } from '../../res/chevron.svg'
import { ReactComponent as SettingsIcon } from '../../res/settings.svg'
import { ReactComponent as CoordinatesIcon } from '../../res/coordinates.svg'
import { ReactComponent as UpdateProfileIcon } from '../../res/updateProfile.svg'
import '../../styles/profile.css';
import {motion as m} from "framer-motion";
import {HeaderLogo} from "../components/headerLogoComponent";
import React, { useState, useEffect } from 'react';
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ClientService from "../Services/Bll/ClientService";
import LoginService from "../../StarterKit/Service/Bll/LoginService";
import HelperService from "../Services/Bll/HelperService";
import ModalComponent from "../components/ModalComponent";
import SelectLanguageComponent from "../components/SelectLanguageComponent";



export default function ProfilePage() {
  if (!HelperService.isLogged()) {
    return (  <Navigate to="/login" replace={true} />)
  }
  let service = new ClientService()
  let loginService = new LoginService()
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    service.read(sessionStorage['lastname']).then(function (result) {
      let res = result.getData();
      setData(res);

    })
  },[])
  const profileLogout = async () => {
    await loginService.logout()
    window.location = "/";
  }

  const anonymize = async () => {
    await service.anonymize(sessionStorage['lastname'])
    window.location.reload()
  }

  const deleteAccount = async () => {
    console.log("delete")
    // await service.delete(sessionStorage['lastname'])
    // navigate("/profile");
  }

  function modalAnonymize () {
    return (
        <div>
          <div>{CacheExtension.getDataFromCache("profile.anonymizeModal")}</div>
        </div>

    )
  }

  function modalDelete () {
    return (
        <div>
          <div>{CacheExtension.getDataFromCache("profile.deleteModal")}</div>
        </div>

    )
  }


  return (
    <>
        <m.div key="container__animation"
               className="container__animation"
               initial={{width:'100vw' , marginLeft : '100vw' }}
               animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
               exit={{ marginLeft : '100vw', x: "inherit" }}
               transition={{duration: 0.30, ease: "linear"}}>
          {data &&
          <>
            <div id="header" className='header__profil titleHead Wallet'>
              <HeaderLogo />
              <div id="headerLogo Wallet"></div>

              <div className="walletHeader profileHeader">
                <h1 id="profileTitle">{CacheExtension.getDataFromCache("profile.title")}</h1>
                <div>
                  <SelectLanguageComponent/>
                  <ModalComponent body={modalAnonymize()} title={"profile.anonymize"} action={anonymize}/>
                  <ModalComponent body={modalDelete()} title={"profile.delete"} action={deleteAccount}/>
                </div>
              </div>


              {/*<div className="container__title__img__profil">*/}
              {/*  <span id="profileIcon"><img src='/profile.png' alt=""/></span>*/}
              {/*</div>*/}
              <h2 id="profileName">{ data.name } { data.lastName }</h2>
              <p id="profileEmail">{ data.mails && Object.keys(data.mails).find(key => data.mails[key] === 1) }</p>
            </div>
            <div id="content">
              <ul className='container__link__profile container__list'>
                <li><Link to={`/profile/update`}><UpdateProfileIcon id="profileMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("profile.update")}</Link><ChevronIcon id="profileMenuChevron" width="1rem" /></li>
                <li><Link to={`/profile/coordinates`}><CoordinatesIcon id="profileMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("profile.coordinates")}</Link><ChevronIcon id="profileMenuChevron" width="1rem" /></li>
                <li><Link to={`/profile/notifications`}><SettingsIcon id="profileMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("profile.notifications")}</Link><ChevronIcon id="profileMenuChevron" width="1rem" /></li>
              </ul>
              <div className="col-lg-2 offset-lg-5">
                <button id="profileLogout" className="button buttonsubmit" onClick={profileLogout}>{CacheExtension.getDataFromCache("profile.logout")} </button>
              </div>
            </div>
          </>
          }
        </m.div>
      <Footer selected="profile"/>
    </>
  );
}


