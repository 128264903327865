import { Link, Navigate } from "react-router-dom";
import { HeaderLogo } from "../components/headerLogoComponent";
import '../../styles/tutorial.css';
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import HelperService from "../Services/Bll/HelperService";

export default function TutorialPage() {
  if (!HelperService.isLogged()) {
    return (  <Navigate to="/login" replace={true} />)
  }

  return (
    <>
        <div id="header">
          <HeaderLogo />
        </div>
        <div id="content">
          <div id="tutorialBlock"> 
            <span id="tutorialBlockIntro">{CacheExtension.getDataFromCache("tutorial.newProduct")}</span>
            {/*<br/><span id="tutorialBlockModel">{ name }</span>*/}
            {/*<br/><div id="tutorialBlockPicture" style={{ backgroundImage: 'url(/products/' + picture + ')' }}>&nbsp;</div>*/}
          </div>
          <span id="tutorialWelcomeText">{CacheExtension.getDataFromCache("tutorial.text")}</span>
          <br/><Link id="tutorialWalletButton" to={`/`} className="button">{CacheExtension.getDataFromCache("tutorial.toWallet")}</Link>
        </div>
    </>
  );
}