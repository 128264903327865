import {Link, Navigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import { ReactComponent as BackIcon } from '../../res/back.svg';
import '../../styles/support.css';
import {motion as m} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";

export default function WarrantyPage() {
    let service = new ProductService()

    let { gtin, serial } = useParams();

    const [data, setData] = useState(null);

    useEffect(() => {
        service.read(gtin, serial).then(function (result) {
            let res = result.getData();
            setData(res);
        })
    },[])


    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                {data &&
                    <>
                        <div className={'titleHead'} id="header">
                            <Link className="backLink" id="entretienBack" to={ `/product/${gtin}/${serial}/service` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                            <h1 id="supportsTitle">{CacheExtension.getDataFromCache("warranty.title")}</h1>
                        </div>
                        <div id="content">
                            warranty
                        </div>
                    </>
                }
            </m.div>
            <Footer selected="wallet"/>
        </>
    );
}