import { Link, Navigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import '../../styles/entretien.css';
import {motion as m} from "framer-motion";
import {AnimatePresence} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import React, {useEffect} from "react";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";


export default function MaintenancePage() {

    let service = new ProductService()

    let { gtin, serial } = useParams();

    const [data, setData] = React.useState(null);

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    useEffect(() => {
        service.read(gtin, serial).then(function (result) {
            let res = result.getData();
            setData(res);
        })
    },[])

    return (
        <>
                <AnimatePresence>
                    <m.div key="container__animation"
                           className="container__animation"
                           initial={{width:'100vw' ,marginLeft : '100vw' }}
                           animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                           exit={{ marginLeft : '100vw', x: "inherit" }}
                           transition={{duration: 0.30, ease: "linear"}}>
                        {data &&
                            <>
                                <div className={'titleHead'} id="header">
                                    <Link className="backLink" id="entretienBack" to={`/product/${gtin}/${serial}/service/` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                                    <h1 id="entretienTitle">{CacheExtension.getDataFromCache('entretien.title')}</h1>
                                    <p>{CacheExtension.getDataFromCache("entretien.hoursEntretien")}</p>
                                </div>
                                maintenance
                                {/*<ul className="container__entretien">*/}
                                {/*    {typeof product.entretien !== 'undefined' && typeof product.entretien.map !== 'undefined' && product.entretien.map(data =>*/}
                                {/*            <li id="productentretienEntry">*/}
                                {/*                <div className="svgProductHistory">*/}
                                {/*<span id="productEntretienTag">*/}

                                {/*</span>*/}
                                {/*                </div>*/}
                                {/*                <div>*/}
                                {/*                    <div>*/}
                                {/*                        <p className="LabelTextEntretien">{CacheExtension.getDataFromCache("entretien.read")}</p>*/}
                                {/*                        <span id="productHistoryDate">{data.lieux}</span>*/}
                                {/*                        <img className="mapEntretien" src="/map.png" alt=""/>*/}
                                {/*                    </div>*/}

                                {/*                    <div>*/}
                                {/*                        <p className="LabelTextEntretien">{CacheExtension.getDataFromCache("entretien.datehours")}</p>*/}
                                {/*                        <div className="entretienContainerDay">*/}
                                {/*                            <span id="productHistoryDate">{data.Day}</span>*/}
                                {/*                            <span>-</span>*/}
                                {/*                            <span id="productHistoryDate">{data.HourStart}</span>*/}
                                {/*                            <span className="productHistoryDate">-</span>*/}
                                {/*                            <span id="productHistoryDate">{data.HoursFinish}</span>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                    <div>*/}
                                {/*                        <p className="LabelTextEntretien">{CacheExtension.getDataFromCache("entretien.incluse")}</p>*/}
                                {/*                        <span id="productHistoryDate">*/}
                                {/*        <p>Devis</p><p>Nettoyage De la Montre</p><p>Scan de tracabilité de l'entretien</p><p>Champagne,Boisson Chaude,Boisson Fraiche</p>*/}
                                {/*    </span>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*    )}*/}
                                {/*</ul>*/}
                            </>
                        }
                    </m.div>
                    <Footer selected="wallet"/>
                </AnimatePresence>
        </>
    );
}