export default class Sk{
    static C_skLogDebug = 0
    static C_skLogInfo = 1
    static C_skLogWarning = 2
    static C_skLogError = 3
    static C_sklogoutURL = "/logout"
    static ckeditorConfigFiles = {}
    static infoTimeout = 2000
    static errorTimeout = 30
    static disconnectTimeout = 60000
    static sessionLength = 10
    static phoneIsMandatory = 'yes'
    static passwordValidation = 'yes'
    static dateStringFormat = 'y-m-d'
    static logLevel = 0
    static filters = []
    static forms = []




    /********************/
    static skLog(pType, pMessage) {
        if (pType >= this.logLevel) {
            var log = "";
            var d = new Date();
            switch(pType) {
                case this.C_skLogInfo:
                    log = '    [Info ' + d.toLocaleTimeString() + '] ' + pMessage;
                    break;
                case this.C_skLogWarning:
                    log = '  [Warning ' + d.toLocaleTimeString() + '] ' + pMessage;
                    break;
                case this.C_skLogError:
                    log = '[Error ' + d.toLocaleTimeString() + '] ' + pMessage;
                    break;
                default:
                    log = '    [Debug] ' + pMessage;
            }
            console.log(log);
        }
    }
    /********************/
    static skComponentShow(pComponent) {
        if ($(pComponent).hasClass('hidden')) {
            $(pComponent).removeClass('hidden');
        }
    }
    /********************/
    static skComponentHide(pComponent) {
        if (! $(pComponent).hasClass('hidden')) {
            $(pComponent).addClass('hidden');
        }
    }
    /********************/
    static skComponentRemoveClass(pComponent, pClass) {
        if ($(pComponent).hasClass(pClass)) {
            $(pComponent).removeClass(pClass);
        }
    }
    /********************/
    static skComponentAddClass(pComponent, pClass) {
        if (! $(pComponent).hasClass(pClass)) {
            $(pComponent).addClass(pClass);
        }
    }
    /********************/
    static skSuccessNoAction(pData) {
        this.skLog(this.C_skLogDebug, "SK : skSuccessNoAction");
        $("body").css('cursor','default');
    }
    /********************/
    static skSuccessReload(pData) {
        this.skLog(C_skLogDebug, "SK : skSuccessReload");
        location.reload();
    }
    /********************/
    static skSuccessShowMessage(pData) {
        this.skLog(this.C_skLogDebug, "SK : skSuccessShowMessage");
        $("body").css('cursor','default');
        this.skShowInfo(pData.message);
    }
    /********************/
    static skShowError(pMsg) {
        if ($("#siteMessages").length === 0){
            var div = document.createElement('div')
            div.id = "siteMessages";
            div.classList = "message invisible pt-2 pb-2 pl-4 pr-4 m-2";
            document.body.append(div)
        }

        this.skLog(this.C_skLogDebug, "SK : skShowError");
        $("#siteMessages").text(pMsg);
        this.skComponentAddClass("#siteMessages", 'error');
        this.skComponentRemoveClass("#siteMessages", 'info');
        this.skComponentRemoveClass("#siteMessages", 'invisible');
        setTimeout(() => {this.skHideMessage(this)}, this.infoTimeout);
    }
    /********************/
    static skShowInfo(pMsg) {

        if ($("#siteMessages").length === 0){
            var div = document.createElement('div')
            div.id = "siteMessages";
            div.classList = "message invisible pt-2 pb-2 pl-4 pr-4 m-2";
            document.body.append(div)
        }

        this.skLog(this.C_skLogDebug, "SK : skShowInfo");
        $("#siteMessages").text(pMsg);
        this.skComponentAddClass("#siteMessages", 'info');
        this.skComponentRemoveClass("#siteMessages", 'error');
        this.skComponentRemoveClass("#siteMessages", 'invisible');
        setTimeout(() => {this.skHideMessage(this)}, this.infoTimeout);
    }
    /********************/
    static skHideMessage(context) {
        this.skLog(this.C_skLogDebug, "SK : skHideMessage");
        this.skComponentRemoveClass("#siteMessages", 'info');
        this.skComponentRemoveClass("#siteMessages", 'error');
        this.skComponentAddClass("#siteMessages", 'invisible');
    }

    static skExportCSV(pTitle, pFilename, pPrefix = '') {
        skLog(C_skLogDebug, "SK : skExportCSV To review");
        let csvContent = "";

        csvContent = '"' + pTitle + '"\n';

        var csvFilter = "";
        var hasFilter = false;
        $('[data-csv' + pPrefix + 'filter]').each(function () {
            if ($.isNumeric($(this).val())) {
                csvFilter += ';"' + $(this).data('csv' + pPrefix + 'filter').trim() + '";' + $(this).val();
            } else {
                csvFilter += ';"' + $(this).data('csv' + pPrefix + 'filter').trim() + '";"' + $(this).val() + '"';
            }
            if ($(this).val() != '') {
                hasFilter=true;
            }
        });

        if (hasFilter) {
            csvContent += '"filters"' + csvFilter + "\n";
        }

        csvContent += "reference";
        $('[data-csv' + pPrefix + 'header]').each(function () {
            var type = $(this).data('csv' + pPrefix + 'header');
            var val = "";
            switch (type) {
                case "val" :
                    val = $(this).val().trim();
                    break;
                case "text" :
                    val = $(this).text().trim();
                    break;
            }
            if ($.isNumeric(val)) {
                csvContent += ';' + val;
            } else {
                csvContent += ';"' + val + '"';
            }
        });
        csvContent += "\n";
        $('[data-index]').each(function () {
            if (! $(this).hasClass('hidden')) {
                csvContent += "'" + $(this).data('index') ;
                $(this).find('[data-csv' + pPrefix + "data]").each(function () {
                    var type = $(this).data('csv' + pPrefix + 'data');
                    var val = "";
                    switch (type) {
                        case "val" :
                            val = $(this).val().trim();
                            break;
                        case "text" :
                            val = $(this).text().trim();
                            break;
                    }
                    if ($.isNumeric(val)) {
                        csvContent += ';' + val;
                    } else {
                        csvContent += ';"' + val + '"';
                    }
                });
                csvContent += "\n";
            }
        });

        var download = function(content, fileName, mimeType) {
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';

            if (navigator.msSaveBlob) { // IE10
                navigator.msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob(["\ufeff",content], {
                    type: mimeType
                }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = "data:text/csv;charset=ISO-8859-1,\uFEFF" + encodeURIComponent(content); // only this mime type is supported
            }
        }

        download(csvContent, pFilename + '.csv', 'text/csv;charset:ISO-8859-1');
    }

}

