import { Link, Navigate } from "react-router-dom";
import { IntlProvider, FormattedMessage } from 'react-intl';

import { Footer } from "../components/footerComponent";
import { ReactComponent as EventSupport } from '../../res/eventSupport.svg'
import { ReactComponent as EventEvent } from '../../res/eventEvent.svg'
import { ReactComponent as EventReminder } from '../../res/eventReminder.svg'
import { ReactComponent as EventWelcome } from '../../res/eventWelcome.svg'
import '../../styles/informations.css';
import {motion as m} from "framer-motion"
import {useEffect, useState,} from "react";
import {HeaderLogo} from "../components/headerLogoComponent";
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import HelperService from "../Services/Bll/HelperService";

export default function InformationsPage() {
  const [post, setPost] = useState(null);
  const [getPost, setGetPost] = useState(null);
  var service = new SettingService()

  if(!post) {
    service.list("").then(function (result) {
      setPost(result)
    })
    return null;
  }


  if (!HelperService.isLogged()) {
    return (  <Navigate to="/login" replace={true} />)
  }
  const icons = {
    event: <EventEvent />,
    support: <EventSupport />,
    reminder: <EventReminder />,
    welcome: <EventWelcome />,
  }
  var products = getLoggedUser().informations;
  var productTable = []
  if (products[0].length == 0) {
    productTable = undefined;
  } else {
    productTable = products;
  }

  return (
    <>
      <IntlProvider locale={localStorage['locale'] ? localStorage['locale'] : 'fr'}>
        <m.div key="container__animation"
               className="container__animation"
               initial={{width:'100vw' ,marginLeft : '100vw' }}
               animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
               exit={{ marginLeft : '100vw', x: "inherit" }}
               transition={{duration: 0.30, ease: "linear"}}>
          <div className={'titleHead Wallet'} id="header">
            <HeaderLogo />
            <div className="walletHeader">
              <h1 id="informationsTitle">{CacheExtension.getDataFromCache("informations.title")}</h1>
            </div>
          </div>
          <div id="content">
            <div>
              <div>
                { (productTable) ? (
                getLoggedUser().informations.map( (event, index) =>
                  <ul className="container__lists__info" key={index}>
                    <li className="container__info">
                      <div className={ (event.new === "true") ? 'active container__svg__info':'container__svg__info'} ><span id={ (event.new === "true") ? 'informationsIconNew':'informationsIcon' }><div className={ event.type }></div></span></div>
                      <div className="container__content__info">
                        <div className="container__title__day__info">
                          <p id="informationsEvent"><FormattedMessage id={ "informations.event." + event.type} defaultMessage={ "informations.event." + event.type}/></p>
                          <p id="informationsEventTime"><FormattedMessage id={ "informations.time." + event.type} defaultMessage={ "informations.time." + event.type}/></p>
                        </div>
                        <p id="informationsText">{event.title}</p>
                      </div>
                    </li>
                  </ul>
                )
                ) : (
                <Link id="walletStore" to={`/store`}>{CacheExtension.getDataFromCache("info.empty")}</Link>
                ) }
              </div>
            </div>
          </div>
        </m.div>
        <Footer selected="informations"/>
      </IntlProvider>
    </>
  );
}