import {Link, Navigate} from "react-router-dom";
import React, {useEffect, useState,} from "react";
import { Footer } from "../components/footerComponent";
import { ReactComponent as ArrowUpIcon } from '../../res/arrowUp.svg'
import '../../styles/store.css';
import {motion as m} from "framer-motion";
import '../../styles/index.css';
import {HeaderLogo} from "../components/headerLogoComponent";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import HelperService from "../Services/Bll/HelperService";


export default function StorePage() {
  if (!HelperService.isLogged()) {
    return (  <Navigate to="/login" replace={true} />)
  }
  return (
    <>
        <m.div key="container__animation"
               className="container__animation"
               initial={{ width:'100vw' , marginLeft : '100vw' }}
               animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
               exit={{ marginLeft : '100vw', x: "inherit" }}
               transition={{duration: 0.30, ease: "linear"}}>
          <div className={'titleHead Wallet'} id="header">
            <HeaderLogo />
            <div className="walletHeader">
              <h1 id="storeTitle">{CacheExtension.getDataFromCache("store.title")}</h1>
            </div>
          </div>
        <div id="content">
          <div id="storeText" className="textPR">{CacheExtension.getDataFromCache("store.text")}</div>
          <div className="storeImage">
            <div id="storeImg1" className="storeImageDetail"></div>
            <div id="storeImg2" className="storeImageDetail"></div>
            <div id="storeImg3" className="storeImageDetail"></div>
          </div>
          <a  href={CacheExtension.getDataFromCache("store.url")} id="storeButton" className="button buttonsubmit" target="_blank" rel="noreferrer">{CacheExtension.getDataFromCache("store.button")}<ArrowUpIcon /></a>
        </div>
      </m.div>
        <Footer selected="store"/>
    </>
  );

}