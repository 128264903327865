import React from "react";
import PropTypes from "prop-types";
import {isDisabled} from "@testing-library/user-event/dist/utils";


export const IconActionButton = (props) => {
    $(function () { //todo call once
        $('[data-toggle="tooltip"]').tooltip()
    })
    return (
        <>
            <button className={props.className ?? "btn actionLink"} onClick={props.action} disabled={!props.state} data-toggle={props.tooltip && "tooltip"} data-placement="top" title={props.tooltip && props.tooltip}>
                {props.state === false ? props.disabledIcon : props.icon }
            </button>
        </>
    );
}

IconActionButton.propTypes = {
    action : PropTypes.func.isRequired,
    className : PropTypes.string,
    icon : PropTypes.element.isRequired,
    disabledIcon : PropTypes.element,
    state : PropTypes.bool.isRequired,
    tooltip: PropTypes.string
}