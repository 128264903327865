import {BaseApiService} from "../BaseApiService";


export default class ApiGroups extends BaseApiService {

    async getGroups() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language']+ `/groups`);
        return await this.callApi();
    }

    async addGroup(data) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(data);
        this.setApiRoute(sessionStorage['language'] + `/groups`);
        return await this.callApi();
    }

    async updateGroup(key, data) {
        this.setPutMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(data);
        this.setApiRoute(sessionStorage['language'] + `/groups/${key}`);
        return await this.callApi();
    }

    async deleteGroup(key) {
        this.setDeleteMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + `/groups/${key}`);
        return await this.callApi();
    }
}

