/*******************************************************************
 * Diji Starter Kit
 *
 * RefreshTokenAnswerModel
 *******************************************************************/
export default class RefreshTokenAnswerModel {
    /*******************************************************************
     * Constructor
     *******************************************************************/
    constructor() {
        this.token = '';
        this.refreshToken = '';
    }

    /*******************************************************************
     * Getters and Setters
     *******************************************************************/
    getToken() {
        return this.token;
    }
    setToken(token) {
        this.token = token;
    }

    /********************/
    getRefreshToken() {
        return this.refreshToken;
    }
    setRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
    }
}
