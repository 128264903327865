import { Link, Navigate, useParams } from "react-router-dom";
import React, {useEffect, useState,} from "react";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import '../../styles/productHistory.css';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";

export default function ProductHistoryPage() {
    let service = new ProductService()

    let { gtin, serial } = useParams();

    const [product, setProduct] = useState(null);
    const [data, setData] = useState(null);

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    useEffect(() => {
        service.read(gtin, serial).then(function (result) {
            let res = result.getData();
            setProduct(res);
        })
    },[])

    useEffect(() => {
        if (product && product.key) {
            service.history(product.key).then(function (result) {
                let res = result.getData();
                setData(res);
            })
        }
    }, [product]);

  return (
    <>
        <AnimatePresence>
        <m.div key="container__animation"
               className="container__animation"
               initial={{width:'100vw' , marginLeft : '100vw' }}
               animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
               exit={{ marginLeft : '100vw', x: "inherit" }}
               transition={{duration: 0.30, ease: "linear"}}>
            {data &&
                <>
                    <div className={'titleHead'} id="header">
                        <Link className="backLink" id="productHistoryBack" to={ `/product/${gtin}/${serial}` }><BackIcon className="chevronBack"/>{ product.model.title }</Link>
                        <h1 id="productHistoryTitle">{CacheExtension.getDataFromCache("productHistory.title")}</h1>
                    </div>
                    <div id="content">
                        <div>
                            <ul className="container__list__history">
                                { data.map(data =>
                                    data.message != 'receivedInShop' &&
                                    <li key={data.key} id="productHistoryEntry">
                                        <div className="svgProductHistory"><span id="productHistoryTag"></span></div>
                                        <div>
                                            <span id="productHistoryDate">{ data.date.split('T')[0] }</span>
                                            <span id="productHistoryText">{ data.message }</span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            }
      </m.div>
            <Footer selected="wallet"/>
      </AnimatePresence>
    </>
  );
}