import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from 'react';
import CacheExtension from "../../StarterKit/Filter/CacheExtension";

export default function InputComponent({ onInputChange,placeholder, type, errors, defaultValues = false, mandatory= false, disabled= false }) {
    const [divs, setDivs] = useState([{ id: 0, value: 1, showAddButton: true }]);

    useEffect(() => {
        if (defaultValues) {
            const newDivs = Object.entries(defaultValues).map(([inputValue, value], index) => ({
                id: index,
                value,
                inputValue,
                showAddButton: index === Object.keys(defaultValues).length - 1,
            }));
            setDivs(newDivs);
        }
    }, [defaultValues]);

    useEffect(() => {
        setDivs(divs => [...divs]);
    }, [errors]);

    const addDiv = () => {
        const updatedDivs = [...divs];
        updatedDivs[updatedDivs.length - 1].showAddButton = false;

        const newDiv = { id: divs.length, value: 0, showAddButton: true };
        setDivs([...updatedDivs, newDiv]);
    };


    const removeDiv = (id) => {
        if (divs.length === 1) return;

        const removedDivValue = divs.find((div) => div.id === id).value;
        const updatedDivs = divs.filter((div) => div.id !== id);

        updatedDivs[updatedDivs.length - 1].showAddButton = true;

        setDivs(updatedDivs);

        if (removedDivValue === 1 && id > 0) {
            const previousDivIndex = updatedDivs.findIndex((div) => div.id === id - 1);
            updatedDivs[previousDivIndex].value = 1;
            setDivs(updatedDivs);
        }

        const inputValues = updatedDivs.reduce((obj, div) => {
            if (div.inputValue) {
                obj[div.inputValue] = div.value;
            }
            return obj;
        }, {});

        onInputChange(inputValues);
    };

    const toggleValue = (id) => {
        let updatedDivs = divs.map((div) => {
            if (div.id === id) {
                return { ...div, value: div.value === 0 ? 1 : 1 };
            }
            return div;
        });

        if (updatedDivs.find((div) => div.id === id).value === 1) {
            updatedDivs = updatedDivs.map((div) => {
                if (div.id !== id) {
                    return { ...div, value: 0 };
                }
                return div;
            });
        }

        setDivs(updatedDivs);

        const inputValues = updatedDivs.reduce((obj, div) => {
            if (div.inputValue) {
                obj[div.inputValue] = div.value;
            }
            return obj;
        }, {});

        onInputChange(inputValues);
    };



    const handleInput = (event, id) => {
        const updatedDivs = divs.map((div) => {
            if (div.id === id) {
                return { ...div, inputValue: event.target.value };
            }
            return div;
        });

        setDivs(updatedDivs);

        const inputValues = updatedDivs.reduce((obj, div) => {
            if (div.inputValue) {
                obj[div.inputValue] = div.value;
            }
            return obj;
        }, {});

        onInputChange(inputValues);
    };

    return (
        <>
            {divs.map((div) => (
                <div key={div.id}>
                    <div className="d-flex">
                        <input
                            disabled={disabled}
                            type={type}
                            placeholder={(mandatory ? '* ' : '') + CacheExtension.getDataFromCache(placeholder)}
                            onChange={(event) => handleInput(event, div.id)}
                            value={div.inputValue || ""}
                        />
                        <button
                            type="button"
                            className={"btn " + (div.value === 1 ? "btnWallet" : "btnWalletReverse")}
                            onClick={() => toggleValue(div.id)}
                        >
                            <FontAwesomeIcon className="actionLink" icon="fas fa-check" />
                        </button>
                        {div.showAddButton && (
                            <button type="button" className="btn btnWallet" onClick={addDiv}>
                                <FontAwesomeIcon className="actionLink" icon="fas fa-plus" />
                            </button>
                        )}
                        {div.id !== 0 && (
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => removeDiv(div.id)}
                            >
                                <FontAwesomeIcon className="actionLink" icon="fas fa-minus" />
                            </button>
                        )}
                    </div>
                </div>
            ))}
            {errors && errors.length === 1 && <div className="showError">{errors[0]}</div>}

        </>
    );


}
