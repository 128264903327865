import {Link, Navigate, useParams} from "react-router-dom";
import { IntlProvider, FormattedMessage } from 'react-intl';
import React, {useEffect, useState,} from "react";
import { ReactComponent as EventSupport } from '../../res/eventSupport.svg';
import { ReactComponent as EventEvent } from '../../res/eventEvent.svg';
import { ReactComponent as EventReminder } from '../../res/eventReminder.svg';
import { ReactComponent as EventWelcome } from '../../res/eventWelcome.svg';
import { ReactComponent as BackIcon } from '../../res/back.svg';
import {ReactComponent as ReglageIcon} from "../../res/settings.svg";
import '../../styles/support.css';
import {motion as m} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";
import ProductService from "../Services/Bll/ProductsService";

export default function SupportPage() {
    let service = new ProductService()

    let { gtin, serial } = useParams();

    const [data, setData] = React.useState(null);

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    useEffect(() => {
        service.read(gtin, serial).then(function (result) {
            let res = result.getData();
            setData(res);
        })
    },[])

    return (
        <>
                <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                    {data &&
                        <>
                            <div className={'titleHead'} id="header">
                                <Link className="backLink" id="entretienBack" to={ `/product/${gtin}/${serial}/service` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                                <h1 id="supportsTitle">{CacheExtension.getDataFromCache("support.title")}</h1>
                            </div>
                            <div id="content">
                                <div>
                                    <div>
                                        <ul className="container__lists__supp" >
                                            <li className="container__supp">
                                                <div
                                                    className={'container__svg__supp'}>
                                                <span
                                                    id={'supportsIcon'}><ReglageIcon />
                                                </span>
                                                </div>
                                                <div className="container__content__supp">
                                                    <div className="container__title__day__supp">
                                                        <p id="supportsEvent">{CacheExtension.getDataFromCache("support.email")}
                                                        </p>
                                                    </div>
                                                    <p id="supportsText">{CacheExtension.getDataFromCache("support.emailText")}
                                                    </p>
                                                </div>
                                            </li>
                                            <li className="container__supp">
                                                <div
                                                    className={'container__svg__supp'}>
                                                <span
                                                    id={'supportsIcon'}><ReglageIcon />
                                                </span>
                                                </div>
                                                <div className="container__content__supp">
                                                    <div className="container__title__day__supp">
                                                        <p id="supportsEvent">{CacheExtension.getDataFromCache("support.hours")}
                                                        </p>
                                                    </div>
                                                    <p id="supportsText">{CacheExtension.getDataFromCache("support.hoursOuvert")}
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </m.div>
                <Footer selected="wallet"/>
        </>
    );
}