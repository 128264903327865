import React from "react";
import PropTypes from "prop-types";
export const LogoComponent = (props) => {
    return (
        <>
            <div>
                <img className={'logoApp'} src={props.logo} alt={props.logo}/>
            </div>
            </>
            );
}

LogoComponent.prototype= {
    logo : PropTypes.string,
}