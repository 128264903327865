import {Link, Navigate, useParams, useLocation, json, useNavigate} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ReactComponent as ChevronIcon } from '../../res/chevron.svg'
import { ReactComponent as BackIcon } from '../../res/back.svg'
import { ReactComponent as TokenIcon } from '../../res/token.svg'
import { ReactComponent as WatchIcon } from '../../res/watch.svg'
import { ReactComponent as HourglassIcon } from '../../res/hourglass.svg'
import { ReactComponent as ServicesIcon } from '../../res/services.svg'
import '../../styles/product.css';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";

export default function ProductPage() {
  const service = new ProductService();

  const navigate = useNavigate();

  let { gtin, serial } = useParams()

  const [data, setData] = useState(null);

  const location = useLocation()

  useEffect(() => {
     service.read(gtin, serial).then(function (result) {
      let res = result.getData();
      if(res.length === 0) {
        navigate('/')
      }
      setData(res);
    })
  },[])

  calc = "0px"
  if (location.state) {
    const { from } = location.state
    if (from >= 0) {
      var calc = 155 + (from * 236.31)
    } else {
      var calc = 0;
    }

    if (calc !== 0) {
      calc = calc + "px"
    }
  }

  if (!HelperService.isLogged()) {
    return (  <Navigate to="/login" replace={true} />)
  }

  function displayInput() {
    var container__animation = document.querySelector('.container__animation')
    container__animation.classList.toggle('activePseudo')
  }

    return (
      <>
          <AnimatePresence>
            <m.div key="container__animation"
                   className="container__animation containerAll"
                   initial={{paddingTop : calc ,margin : '0 auto' }}
                   animate={{ paddingTop : '0', transitionEnd : { transform: "inherit"} }}
                   transition={{duration: 0.40, ease: "linear"}}>
              {data &&
                <>
                  <div className="header__product" id="header">
                    <Link className="backLink" id="productTitleBack" to={ `/` }><BackIcon className="chevronBack"/>{CacheExtension.getDataFromCache("product.back")}</Link>
                    <h1 id="productTitle">{ data.model.title }

                    </h1>
                    <h2 id="productName">{data.model.subtitle}</h2>
                    <div id="productPicture" style={{backgroundImage : `url(${data.model.image})`, mixBlendMode:'multiply'}}></div>

                  </div>
                  <div id="contentProduct">
                    <ul className="container__link__product container__list">
                      <li>
                        <Link to={`/product/${gtin}/${serial}/token`}><TokenIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("product.token")}</Link>
                        <ChevronIcon id="productMenuChevron" width="1rem" />
                      </li>
                      <li>
                        <Link to={`/product/${gtin}/${serial}/description`}><WatchIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("product.description")}</Link>
                        <ChevronIcon id="productMenuChevron" width="1rem" />
                      </li>
                      <li>
                        <Link to={`/product/${gtin}/${serial}/history`}><HourglassIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("product.history")}</Link>
                        <ChevronIcon id="productMenuChevron" width="1rem" />
                      </li>
                      <li>
                        <a
                            href={'/UserManual.pdf'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          <ServicesIcon id="productMenuIcon" width="1rem" />
                          {CacheExtension.getDataFromCache("product.manual")}
                        </a>
                        <ChevronIcon id="productMenuChevron" width="1rem" />
                      </li>
                        <li>
                            <Link to={`/product/${gtin}/${serial}/invoice`}><ServicesIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("product.invoice")}</Link>
                            <ChevronIcon id="productMenuChevron" width="1rem" />
                        </li>
                      <li>
                        <Link to={`/product/${gtin}/${serial}/service`}><ServicesIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("product.services")}</Link>
                        <ChevronIcon id="productMenuChevron" width="1rem" />
                      </li>
                    </ul>
                  </div>
                </>
              }
            </m.div>
            <Footer selected="wallet"/>
          </AnimatePresence>
      </>
  );
}






