import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiMedia from "../Api/ApiMedia";

export default class MediaService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiMedia();
        this.#helper = new HelperService();
    }

    async read(key) {
        const result = await this.#api.read(key);
        return result;
    }
}
