import { Link, Navigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import '../../styles/productToken.css';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, { useEffect, useState } from "react";
import ProductService from "../Services/Bll/ProductsService";
import MediaService from "../Services/Bll/MediaService";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";

export default function ProductTokenPage() {
  let service = new ProductService()
  let mediaService = new MediaService()

  let { gtin, serial } = useParams()

  const [data, setData] = useState(null);
  const [image, setImage] = useState(null);

  if (!HelperService.isLogged()) {
    return (  <Navigate to="/login" replace={true} />)
  }

  useEffect(() => {
      service.read(gtin, serial).then(function (result) {
        let res = result.getData();
        setData(res);
      })

  },[])

  useEffect(() => {
    if (data && data.tokenAsset) {
      mediaService.read(data.tokenAsset).then(function (result) {
        let res = result;
        let imageUrl = URL.createObjectURL(res);
        setImage(imageUrl)
      });
    }
  }, [data]);

  return (
    <>
        <AnimatePresence>
          <m.div key="container__animation"
                 className="container__animation"
                 initial={{ width:'100vw' ,marginLeft : '100vw' }}
                 animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                 exit={{ marginLeft : '100vw', x: "inherit" }}
                 transition={{duration: 0.30, ease: "linear"}}>
            {data &&
              <>
                <div className="headerProductToken titleHead" id="header">
                  <Link className="backLink" id="productTokenBack" to={`/product/${gtin}/${serial}`}><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                  <h1 id="productTokenTitle">{CacheExtension.getDataFromCache("productToken.title")}</h1>
                  <span id="productTokenText">{CacheExtension.getDataFromCache("productToken.text")}</span>

                </div>
                <div id="productTokenPicture"><img src={image} alt="token"/></div>
                <div id="content">
                  <span id="productTokenKey">
                    {CacheExtension.getDataFromCache("productToken.model")}
                  </span>
                  <span id="productTokenValue">
                    { data.model.title }
                  </span>
                  <span id="productTokenKey">
                    {CacheExtension.getDataFromCache("productToken.serial")}
                  </span>
                  <span id="productTokenValue">
                    { data.serial }
                  </span>
                  <span id="productTokenKey">
                    {CacheExtension.getDataFromCache("productToken.date")}
                  </span>
                  <span id="productTokenValue">
                    { data.createdOn.split("T")[0] }
                  </span>
                </div>
              </>
            }
          </m.div>
          <Footer selected="wallet"/>
        </AnimatePresence>
    </>
  );
}