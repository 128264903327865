import {Link, Navigate, useNavigate} from "react-router-dom";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import React, {useEffect, useState,} from "react";
import { Footer } from "../components/footerComponent";
import '../../styles/updateProfile.css';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ClientService from "../Services/Bll/ClientService";
import HelperService from "../Services/Bll/HelperService";
import ModalComponent from "../components/ModalComponent";

export default function UpdateProfilePage() {
    const [post, setPost] = React.useState(null);
    const [getPost, setGetPost] = React.useState(null);

    const navigate = useNavigate();

    const service = new ClientService()

    const [data, setData] = useState(null);
    const [form, setForm] = useState(null);
    const [passwordForm, setPasswordForm] = useState({
        password: '',
        confirmation: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        service.read(sessionStorage['lastname']).then(function (result) {
            let res = result.getData();
            setData(res);
            setForm({
                name: res.name,
                lastName: res.lastName,
                age: res.age
            })
        })
    },[])

    const handleFormChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    const handlePasswordFormChange = event => {
        setPasswordForm({
            ...passwordForm,
            [event.target.name]:event.target.value
        });
    }

    const validate = (data) => {
        const newErrors = {};

        if (!data.name || data.name === "") {
            newErrors.name = CacheExtension.getDataFromCache("InvalidFirstname")
        }
        if (!data.lastName || data.lastName === "") {
            newErrors.lastName = CacheExtension.getDataFromCache("InvalidLastname")
        }
        if (!data.age || data.age === "") {
            newErrors.age = CacheExtension.getDataFromCache("InvalidAge")
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const validatePassword = (data) => {
        const newErrors = {};

        if(!data.password || data.password !== data.confirmation) {
            newErrors.password = CacheExtension.getDataFromCache("PasswordDoesntMatch")
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const submit = async (e) => {
        e.preventDefault()

        if(validate(form)) {
            await service.update(data.key, form)
            navigate('/profile')
        }
    }

    const submitPassword = async (e) => {
        e.preventDefault()

        if(validatePassword(passwordForm)) {
            await service.update(data.key, {password: passwordForm.password})
            navigate('/profile')
        }
    }


    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }
  return (
    <>
        <AnimatePresence>
          <m.div key="container__animation"
                 className="container__animation"
                 initial={{width:'100vw' , marginLeft : '100vw' }}
                 animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                 exit={{ marginLeft : '100vw', x: "inherit" }}
                 transition={{duration: 0.30, ease: "linear"}}>
              {data &&
                  <>
                      <div className={'titleHead'} id="header">
                          <Link className="backLink" id="updateProfileBack" to={`/profile`}><BackIcon className="chevronBack"/>{CacheExtension.getDataFromCache("updateProfile.back")}</Link>
                          <h1 id="productTokenTitle">{CacheExtension.getDataFromCache("updateProfile.title")}</h1>
                      </div>
                      <div id="content" style={{marginBottom:'79px'}}>
                          <form onSubmit={(e) => submit(e)}>
                              <span className="labelInput" id="updateProfileFirstnameLabel">{CacheExtension.getDataFromCache("updateProfile.firstname")}</span>
                              <input placeholder={CacheExtension.getDataFromCache("updateProfile.firstname")} type="text" name="name" id="updateProfileFirstname" defaultValue ={ data.name } onChange={handleFormChange}/>
                              {errors.name && <div className="showError">{errors.name}</div>}
                              <span className="labelInput" id="updateProfileLastnameLabel">{CacheExtension.getDataFromCache("updateProfile.lastname")}</span>
                              <input placeholder={CacheExtension.getDataFromCache("updateProfile.lastname")} type="text" name="lastName" id="updateProfileLastname" defaultValue ={ data.lastName } onChange={handleFormChange}/>
                              {errors.lastName && <div className="showError">{errors.lastName}</div>}
                              <span className="labelInput" id="updateProfileAgeLabel">{CacheExtension.getDataFromCache("updateProfile.age")}</span>
                              <input placeholder={CacheExtension.getDataFromCache("updateProfile.age")} type="text" name="age" id="updateProfileAge" defaultValue ={ data.age } onChange={handleFormChange}/>
                              {errors.age && <div className="showError">{errors.age}</div>}
                              <input placeholder={CacheExtension.getDataFromCache("updateProfile.titleField")} type="text" name="title" id="updateProfileTitle" defaultValue ={ data.title } onChange={handleFormChange}/>
                              {errors.title && <div className="showError">{errors.title}</div>}
                              <button id="updateProfileButton"  className="button btnBo buttonsubmit" type="submit">{CacheExtension.getDataFromCache("updateProfile.update")}</button>
                          </form>
                          <form className="mt-5" onSubmit={(e) => submitPassword(e)}>
                              <span className="labelInput" id="updateProfilePasswordLabel">{CacheExtension.getDataFromCache("updateProfile.password")}</span>
                              <input placeholder={CacheExtension.getDataFromCache("updateProfile.password")} type="password" name="password" id="updateProfilePassword" onChange={handlePasswordFormChange}/>
                              <span className="labelInput" id="updateProfileConfirmationLabel">{CacheExtension.getDataFromCache("updateProfile.lastname")}</span>
                              <input placeholder={CacheExtension.getDataFromCache("updateProfile.confirmation")} type="password" name="confirmation" id="updateProfileConfirmation" onChange={handlePasswordFormChange}/>
                              {errors.password && <div className="showError">{errors.password}</div>}
                              <button id="updateProfileButton"  className="button buttonsubmit" type="submit">{CacheExtension.getDataFromCache("updateProfile.updatePassword")}</button>
                          </form>
                      </div>
                  </>
              }
          </m.div>
        </AnimatePresence>
        <Footer selected="profile"/>
    </>
  );
}

