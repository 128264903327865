export default class ApiResult {
    code;
    message;
    context;
    data;

    constructor() {
        this.code = 0;
        this.message = 'Success';
        this.context = null;
        this.data = null;
    }

    getCode() {
        return this.code;
    }

    setCode(code) {
        this.code = code;
    }

    getMessage() {
        return this.message;
    }

    setMessage(message) {
        this.message = message;
    }

    getContext() {
        return this.context;
    }

    setContext(context) {
        this.context = context;
    }

    getData() {
        return this.data;
    }

    setData(data) {
        this.data = data;
    }
}