import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";

export default class ApiWallet extends BaseApiService {

    // Get rights
    async list() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/list_user");
        return await this.callApi();
    }

    async import(file, product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedFileInBody();
        this.setFile(file);
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/importInvoice");
        return await this.callApi();
    }
}
