import SettingService from "../../../StarterKit/Service/Bll/SettingService";

export default class HelperService {

    async setLocalStorage() {
        var serviceSettings = new SettingService();
        // language always set to fr
        // if (!localStorage.getItem('locale')) {
        //     localStorage.setItem('locale', sessionStorage['language'] ? sessionStorage['language'] : 'EN');
        // }
        localStorage.setItem('locale', 'FR');
        sessionStorage.setItem('language', 'FR');
        if (!localStorage.getItem('securityZone')) {
            var securityZone = await serviceSettings.getFromGroup("securityZone");
            localStorage.setItem(securityZone.context.group, JSON.stringify(securityZone.getData()));
        }
        var resultCache = await serviceSettings.getFromGroup("cacheFront");
        var settings = resultCache.getData();
        if (Object.keys(settings).length > 0) {
            for (const settingsKey in settings) {
                if (!localStorage.getItem(settingsKey)) {
                    var resultSettings = await serviceSettings.getFromGroup(settingsKey);
                    localStorage.setItem(settingsKey, JSON.stringify(resultSettings.getData()));
                }
            }
        }
    }
    static isLogged() {
        return sessionStorage['isLogged'] === 'yes';
    }

    static async selectLanguage(selected) {
        var serviceSettings = new SettingService();
        // language always set to fr
        // sessionStorage['language'] = selected
        // localStorage['locale'] = selected
        sessionStorage['language'] = 'FR'
        localStorage['locale'] = 'FR'
        var resultSettings = await serviceSettings.getFromGroup("labelFront");
        localStorage.setItem("labelFront", JSON.stringify(resultSettings.getData()));

    }

}
