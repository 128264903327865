import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import '../../styles/modal.css'
import {useEffect, useState} from "react";
import PropTypes from "prop-types";


export default function ModalComponent ({title, body, action}) {

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <button className="button buttonsubmit" onClick={handleOpen}>
                {CacheExtension.getDataFromCache(title)}
            </button>
            {open && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-50" style={{maxWidth: "none"}}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{CacheExtension.getDataFromCache(title)}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className={'modal-body'}>
                                {body && body}
                                <div className={'d-flex justify-content-center align-items-center'}>
                                    <div>
                                        <button className={"button buttonsubmit"} onClick={()=> {
                                            action();
                                            handleClose()
                                        }}>{CacheExtension.getDataFromCache("Validate")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
ModalComponent.propTypes = {
    action : PropTypes.func.isRequired,
    title : PropTypes.string.isRequired,
    body: PropTypes.element
}