import { Link } from "react-router-dom";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import '../../styles/custom.css';


export const Footer = (props) => {
  const {selected} = props;
  var nbNew = 0;
  // const infos = getLoggedUser().informations;
  // if (infos) {
  //     for (let i = 0; i < infos.length; i ++) {
  //         if (infos[i].new === "true") {
  //             nbNew ++;
  //         }
  //     }
  // }
  return (
    <>
      <div id="footer">
        <div className="footerItemContainer">
              <div className={ (selected === "wallet")? "selected": "" }>
                <Link id="footerWallet" to={`/`}>
                  <div className="footerPicto"></div>

                  <div className="footerTitle">{CacheExtension.getDataFromCache("footer.title")}</div>
                </Link>
              </div>
              <div className={ (selected === "store")? "selected": "" }>
                <Link id="footerStore" to={`/store`}>
                  <div className="footerPicto"></div>
                  <div className="footerTitle">{CacheExtension.getDataFromCache("footer.store")}</div>
                </Link>
              </div>
              {/*<div className={ (selected === "informations")? "selected": "" }>*/}
              {/*  <Link id="footerInfo" to={`/infos`}>*/}
              {/*    <div className="footerPicto"></div>*/}
              {/*    { (nbNew !== 0) ? (<div id="footerNewCount"></div>) : ('') }*/}
              {/*    <div className="footerTitle">{CacheExtension.getDataFromCache("footer.infos")}</div>*/}
              {/*  </Link>*/}
              {/*</div>*/}
              <div className={ (selected === "profile")? "selected": "" }>
                <Link id="footerProfile" to={`/profile`}>
                  <div className="footerPicto"></div>
                  <div className="footerTitle">{CacheExtension.getDataFromCache("footer.profile")}</div>
                </Link>
              </div>
            <div className={"tiret"}>

            </div>
        </div>
      </div>
    </>
  );
}
setTimeout(() => {
    var footerItemContainer = document.querySelectorAll('.footerItemContainer div')
    var tiret = document.querySelector('.tiret')
    for (let i = 0; i < footerItemContainer.length ; i++) {
        footerItemContainer[i].addEventListener('click',function () {
            var width = footerItemContainer[i].offsetWidth
            var leftposition = footerItemContainer[i].offsetLeft
            tiret.style.left = leftposition + 'px'
            tiret.style.width = width + 'px';
        })
    }
}, "300")
