import {Link, Navigate, useNavigate} from "react-router-dom";
import { ReactComponent as QueryIcon } from '../../res/walletAdd.svg';
import React, {useEffect, useState} from "react";
import { HeaderLogo } from "../components/headerLogoComponent";
import { Footer } from "../components/footerComponent";
import '../../styles/wallet.css';
import {motion as m} from "framer-motion"
import WalletService from "../Services/Bll/WalletService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductCard from "../components/ProductCard";
import HelperService from "../Services/Bll/HelperService";
import ClientService from "../Services/Bll/ClientService";

export default function WalletPage() {
  const [data, setData] = useState(null);
  const service = new WalletService();

  const navigate = useNavigate()
  const logged = HelperService.isLogged()
  const deleted = false;

  useEffect(() => {
      if (logged) {
        service.list().then(function (result) {
          let res = result.getData();
          setData(res);
        })
    } else {
      navigate('/login')
    }
  }, [])




  const viewsItem = localStorage.getItem('backclick')
  var directionAnimation = '';
  var nameclass = '';
  if (viewsItem == 1) {
    directionAnimation = '-100vw'
    nameclass = "reverseAnim"
  } else {
    directionAnimation = '100vw'
    nameclass = ""
  }

  return (
      <>
        {data &&
            <>
              <m.div key="container__animation"
                     className="container__animation"
                     initial={{ width:'100vw' ,marginLeft : directionAnimation }}
                     animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                     exit={{ marginLeft : '100vw', x: "inherit" }}
                     transition={{duration: 0.30, ease: "linear"}}>
                <div  className={'titleHead Wallet walletPage' + nameclass} id="header">
                  <HeaderLogo />
                  {deleted &&
                      <div className="deleteBanner col-12 col-lg-4">
                        <span className="m-2">{CacheExtension.getDataFromCache("wallet.bannerDelete")}</span>
                        <button className="deleteCancel">{CacheExtension.getDataFromCache("wallet.cancelDelete")}</button>
                      </div>
                  }
                  <div className="walletHeader">
                    <h1 id="walletTitle">{CacheExtension.getDataFromCache("wallet.title")}</h1>
                    <span id="walletAddProduct"><Link to={`/new`}>{CacheExtension.getDataFromCache("wallet.add")}<QueryIcon width="1rem" /></Link></span>
                  </div>
                </div>

                <div id="content">
                  {data.length > 0 &&
                      <>
                        <div className="row walletProducts">
                          {data.map((data, index) =>
                              <ProductCard key={index} data={data}/>
                          )}
                        </div>
                      </>
                  }
                </div>
              </m.div>
              <Footer selected="wallet"/>
            </>
        }
      </>
  );
}









