import {Link, Navigate, useNavigate} from "react-router-dom";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import {Footer} from "../components/footerComponent";
import {motion as m} from "framer-motion";
import {AnimatePresence} from "framer-motion"
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import React, {useEffect, useState,} from "react";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import Select from "react-select";
import ClientService from "../Services/Bll/ClientService";
import HelperService from "../Services/Bll/HelperService";

export default function NotificationsPage() {
    const service = new ClientService()
    const settingService = new SettingService()
    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [settings, setSettings] = useState(null);
    const [optin, setOptin] = useState([]);

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    useEffect(() => {
        service.read(sessionStorage['lastname']).then(function (result) {
            let res = result.getData();
            setData(res);

        })

        settingService.getFromGroup('optInTypes').then(function (result) {
            setSettings(Object.keys(result.getData()))
        })
    }, [])

    useEffect(() => {
        if(data) {
            setOptin(Object.keys(data.optIns).map((key,i)=> key))

        }
    }, [data])

    const onChangeSelect = (newValue, actionMeta) => {
        let newV = newValue ? newValue.map(item => item.value) : [];

        switch (actionMeta.action) {
            case 'remove-value':
                newV = newV.filter(item => item !== actionMeta.removedValue.value);
                break;
            case 'clear':
                newV = [];
                break;
            case 'select-option':
                const value = actionMeta.option.value;
                if (!newV.includes(value)) {
                    newV = [...newV, value];
                }
                break;
            default:
                break;
        }
            setOptin(newV);
    }

    const submit = async (e) => {
        e.preventDefault()

        let payload = {};
        console.log(optin)

        settings.forEach((setting) => {
            if (optin.includes(setting)) {
                payload[setting] = 1;
            }
            else {
                payload[setting] = 0;
            }
        });

        await service.update(data.key, {optIns : payload})
        navigate('/profile')
    }

    return (
        <>
            <AnimatePresence>
                <m.div key="container__animation"
                       className="container__animation"
                       initial={{width:'100vw' ,marginLeft : '100vw' }}
                       animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                       exit={{ marginLeft : '100vw', x: "inherit" }}
                       transition={{duration: 0.30, ease: "linear"}}>
                    <div className={'titleHead'} id="header">
                        <Link className="backLink" id="updateProfileBack" to={`/profile`}><BackIcon className="chevronBack"/>{CacheExtension.getDataFromCache("updateProfile.back")}</Link>
                        <h1 id="productTokenTitle">{CacheExtension.getDataFromCache("profile.notifications")}</h1>
                    </div>
                    {data && optin &&
                        <div id="content">
                            <form onSubmit={(e) => submit(e)}>
                                <span className="labelInput" id="updateProfileOptin">{CacheExtension.getDataFromCache("notifications.optin")}</span>
                                <Select
                                    isMulti
                                    name="optin"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            neutral20: 'rgb(255, 158, 23)',
                                            neutral30: 'rgb(255, 158, 23)',
                                            primary25: 'rgba(255, 158, 23, 0.5)',
                                            danger: 'rgb(255, 158, 23)',
                                            dangerLight: 'rgba(255, 158, 23, 0.2)',
                                            primary: 'black',
                                        },
                                    })}
                                    defaultValue={data && Object.keys(data.optIns)
                                        .filter(key => data.optIns[key] !== 0)
                                        .map(key => ({ label: CacheExtension.getDataFromCache(key), value: key }))}
                                    placeholder={CacheExtension.getDataFromCache("notifications.select")}
                                    options={settings && settings.map((key, i) => ({value: key, label: CacheExtension.getDataFromCache(key)}))}
                                    onChange={onChangeSelect}
                                />
                                <button id="updateProfileButton"  className="button buttonsubmit" type="submit">{CacheExtension.getDataFromCache("updateProfile.update")}</button>
                            </form>
                        </div>
                    }
                </m.div>
            </AnimatePresence>
            <Footer selected="profile"/>
        </>
    );
}