import {BaseApiService} from "../BaseApiService";

/*******************************************************************
 * Diji Starter Kit
 *
 * ApiLanguage
 *******************************************************************/
export default class ApiLanguages extends BaseApiService {
    /*******************************************************************
     * public function
     *******************************************************************/
    async getLanguages(lang) {
        this.setGetMethod();
        this.setSecureByService();
        this.setApiRoute(lang + "/languages");
        return await this.callApi();
    }

/********************/
    async addLanguage(lang,language) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(language);
        this.setApiRoute(lang + "/languages");
        return await this.callApi();
    }
}
