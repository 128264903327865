import ApiResult from "../Model/ApiResult";
import LoginModel from "../Model/LoginModel";
import axios from 'axios';
import HelperService from "./Bll/HelperService";
import RefreshTokenAnswerModel from "../Model/RefreshTokenAnswerModel";
import Sk from "../Sk";
import configData from "../../config.json";


export class BaseApiService {

    #API_POST = " POST";
    #API_GET = " GET";
    #API_PUT = " PUT";
    #API_DELETE = "DELETE";

    #API_SECURE_BY_USER = "user";
    #API_SECURE_BY_SERVICE = "service";
    #API_UNSECURE = "unsecure";
    #isDebug;
    #that;

    nbExec;
    srv;
    encoders;
    normalizers;
    serializer;
    apiUrl;
    apiServiceAccount;
    apiServicePwd;
    apiService;
    body;
    hasBody;
    needBody;
    file;
    hasFile;
    needFile;
    method;
    hasMethod;
    secure;
    hasSecure;
    apiRoute;
    hasApiRoute;
    token

    constructor(params = null) {
        this.#isDebug = params && params['debug'] !== undefined ? params['debug'] : false;
        this.helper = new HelperService();
        this.apiUrl = configData.SERVER_URL;
        this.apiServiceAccount =  configData.API_SERVICE_ACCOUNT;
        this.apiServicePwd =  configData.API_SERVICE_PWD;
        this.apiServiceService =  configData.API_SERVICE_SERVICE;

        this.method = this.#API_GET;
        this.hasMethod = false;
        this.token = '';

        this.secure = this.#API_SECURE_BY_SERVICE;
        this.hasSecure = false;

        this.body = null;
        this.hasBody = false;
        this.needBody = false;

        this.file = null;
        this.hasFile = false;
        this.needFile = false;

        this.apiRoute = "";
        this.hasApiRoute = false;
        this.nbExec = 0;
        this.#that = this
    }

    setApiRoute(apiRoute) {
        this.apiRoute = apiRoute;
        this.hasApiRoute = true;
    }

    setBody(object) {
        this.body = object;
        this.hasBody = true;
        this.hasFile = false;
    }

    callNeedDataInBody() {
        this.needBody = true;
        this.needFile = false;
    }

    setFile(file) {
        this.file = file;
        this.hasFile = true;
        this.hasBody = false;
    }

    callNeedFileInBody() {
        this.needFile = true;
        this.needBody = false;
    }

    /********************/
    setGetMethod() {
        this.method = this.#API_GET;
        this.hasMethod = true;
    }

    setPostMethod() {
        this.method = this.#API_POST;
        this.hasMethod = true;
    }

    setPutMethod() {
        this.method = this.#API_PUT;
        this.hasMethod = true;
    }

    setDeleteMethod() {
        this.method = this.#API_DELETE;
        this.hasMethod = true;
    }

    /********************/
    setSecureByUser() {
        this.secure = this.#API_SECURE_BY_USER;
        this.hasSecure = true;
    }

    setSecureByService() {
        this.secure = this.#API_SECURE_BY_SERVICE;
        this.hasSecure = true;
    }

    setUnsecure() {
        this.secure = this.#API_UNSECURE;
        this.hasSecure = true;
    }
    async callApi() {
        this.nbExec ++;

        var flag = true;
        var result = new ApiResult();
        result.setContext(this.body)

        if ((! this.hasSecure) ||
            (! this.hasMethod) ||
            (! this.hasApiRoute) ||
            (this.needBody && (! this.hasBody)) ||
            (this.needFile && (! this.hasFile)) ||
            (this.needFile && this.needBody)) {
            flag = false;
            result.setMessage('Development error ! BaseApiService unitialized before callAPI');
            result.setCode(-1);
        }

        if (flag) {

            if (this.hasFile) {

                let formData = new FormData()
                formData.append('file',this.file.getFile())
                for (const key in this.body) {
                    formData.append(key,this.body[key])
                }
                this.body = formData
            }

            if (this.secure !== this.#API_UNSECURE) {
                if (this.secure === this.#API_SECURE_BY_USER) {
                    this.token = this.helper.getToken();
                } else {
                    var token = await this.getServiceToken();
                    if (token === "") {
                        flag = false;
                        result.setMessage('Could not access API - Account service error');
                        result.setCode(-1);
                    } else {
                        this.token = token;
                    }
                }
            }
        }
        if (flag) {
            var url = this.apiUrl.trim() + this.apiRoute;
            var output = await this.#axiosCall(this.method,url,this.body)
            if (output === undefined || output.status === 401) {
                result.setCode(401);
                result.setMessage("Accès interdit");
                Sk.skShowError(result.getMessage())
                document.location.href = '/logout'
            }
            if (typeof output !== "object") {
                if(this.#isDebug) {
                    result.setCode(500);
                    return;
                } else {
                    result.setMessage("Accès interdit");
                }
            } else if (output.data) {
                if (output.data.token && output.data.refresh_token) {
                    result.setData(output);
                } else if (output.data) {
                    if (output.data.code !== undefined && output.data.context !== undefined && output.data.data !== undefined && output.data.message !== undefined) {
                        result.code = output.data.code
                        result.context = output.data.context
                        result.data = output.data.data
                        result.message = output.data.message
                    } else if (output.status !== 201) {
                        // result.setCode(-902);
                        result.setMessage("Accès interdit");
                    }
                }
            }
            if (result.getContext() === null) result.setContext([]);
            if (result.getData() === null) result.setData([]);
        }
        if (result.getCode() !== 0) {
            if (result.getCode() === 401 && this.nbExec === 1) {
                result = await this.refreshToken();
            } else if (result.getCode() === -902) {
                result.setCode(401);
                result.setMessage("Accès interdit");
                Sk.skShowError(result.getMessage())
                document.location.href = '/logout'
            }
        }
        if (result.getCode() <= 0 || result.getCode() > 399) {
            Sk.skShowError(result.getMessage())
        }
        if (result.getCode() >= 0 && result.getCode() <= 399) {
            Sk.skShowInfo(result.getMessage())
        }
        return result;
    }


    async getServiceToken() {
        var data = new LoginModel();
        data.setUsername(this.apiServiceAccount);
        data.setPassword(this.apiServicePwd);
        data.setService(this.apiServiceService);

        var url = this.apiUrl.trim() + "login_check";
        var result = await this.#axiosCall(this.#API_POST,url,data)
        if (result === undefined){
            return ""
        }
        return result.data.token;
    }

    async refreshToken() {
        var data = {"refresh_token" : this.helper.getRefreshToken()};

        var url = this.apiUrl.trim() + "refresh";

        var output = await this.#axiosCall(this.#API_POST,url,data);
        if (output.data.token && output.data.refresh_token) {
            var result = new RefreshTokenAnswerModel();
            result.token = output.data.token;
            result.refreshToken = output.data.refresh_token;
            this.helper.setToken(result.getToken());
            this.helper.setRefreshToken(result.getRefreshToken());
            return this.callApi();
        } else {
            result = new ApiResult();
            result.setCode(-902);
            result.setMessage("Accès interdit");
            return result;
        }
    }

    async #axiosCall(verb,url,data = null) {
        var token = this.token;
        var that = this
        let headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': this.hasFile ? 'multipart/form-data' : 'application/json',
            'Access-Control-Allow-Origin' : '*',
            "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
            'Authorization': 'bearer '+ token
        }

        switch (verb){
            case this.#API_GET:
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: url,
                    headers : headers,
                };
                var result = await axios.request(config).catch(function (err) {
                    if (err) {
                        console.log(err)
                        return err.response
                    }
                });

                break;
            case this.#API_POST:
                var result =  await axios.post(url,  data, {headers: headers}).catch(function (err) {
                    if (err) {
                        console.log(err)
                        return err.response
                    }
                })
                break;
            case this.#API_PUT:
                var result =  await axios.put(url,  data, {headers: headers}).catch(function (err) {
                    if (err) {
                        console.log(err)
                        return err.response
                    }
                })
                break;
            case this.#API_DELETE:
                var result = await axios.delete(url, {headers: headers}).catch(function (err) {
                    if (err) {
                        console.log(err)
                        return err.response
                    }
                })
                break;
        }
        return result;
    }
}

