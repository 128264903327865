import ApiWallet from "../Api/ApiWallet";
import ApiProfiles from "../../../StarterKit/Service/Api/ApiProfiles";
import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiLogin from "../../../StarterKit/Service/Api/ApiLogin";
import ApiOwn from "../Api/ApiOwn";

export default class OwnService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiOwn();
        this.#helper = new HelperService();
    }

    async ownAndCreate(data,product) {
        const result = await this.#api.ownAndCreate(data,product);
        return result;
    }

    async ownAndUpdate(data, client) {
        const result = await this.#api.ownAndUpdate(data, client);
        return result;
    }

    async own(product) {
        const result = await this.#api.own(product);
        return result;
    }
}