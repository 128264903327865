import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";
import configData from "../../../config.json";
import HelperService from "../../../StarterKit/Service/Bll/HelperService";


export default class ApiMedia extends BaseApiService {

    async read(key) {
        let token = await this.getServiceToken()
        const res = fetch(configData.SERVER_URL +sessionStorage['language'] + "/medias/" + key,{
            headers: {
                'Accept': '*/*',
                'Content-Type': 'image/png',
                'Access-Control-Allow-Origin' : '*',
                "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
                'Authorization': 'bearer '+ token
            },
            method : 'GET'
        }).then(response => response.blob())
        return res
    }
}
