/*******************************************************************
 * Diji Starter Kit
 *
 * LoginModel
 *******************************************************************/
export default class LoginModel {
    /*******************************************************************
     * Constructor
     *******************************************************************/
    constructor() {
        this.username = '';
        this.password = '';
        this.service = ''
    }

    /*******************************************************************
     * Getters and Setters
     *******************************************************************/
    getUsername() {
        return this.username;
    }
    setUsername(username) {
        this.username = username;
    }

    /********************/

    getPassword() {
        return this.password;
    }
    setPassword(password) {
        this.password = password;
    }

    getService() {
        return this.service;
    }
    setService(service) {
        this.service = service;
    }
}