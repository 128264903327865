import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {IntlProvider} from "react-intl";
import {RouterProvider} from "react-router-dom";
import router, {removeHashFromUrl} from "./router";
import './StarterKit/css/message.css';
import './styles/appMessage.css';
import { pdfjs } from 'react-pdf';
import { library } from '@fortawesome/fontawesome-svg-core'
// import your icons
import { fas } from '@fortawesome/free-solid-svg-icons'
import HelperService from "./App/Services/Bll/HelperService";
import SkHelperService from "./StarterKit/Service/Bll/HelperService";
var skHelperService = new SkHelperService()
var helperService = new HelperService()
removeHashFromUrl();

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

if (sessionStorage.length === 0){
    skHelperService.logoutUser()
}
if (location.pathname === '/login' && sessionStorage['isLogged'] !== 'yes'){
    localStorage.clear()
}
await helperService.setLocalStorage();
setInterval(async () => {
    await helperService.setLocalStorage();
}, 5 * 60 * 1000);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <RouterProvider router={router} />
);



// // If you want your App to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your App, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
library.add(fas)
