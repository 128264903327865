import {Link, Navigate, useParams} from "react-router-dom";
import React, { useEffect, useState} from "react";
import { ReactComponent as VolIcon } from '../../res/vol.svg';
import { ReactComponent as BackIcon } from '../../res/back.svg';
import '../../styles/lost.css';
import {motion as m} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import CollapseComponent from "../../StarterKit/Components/CollapseComponent";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";

export default function LostPage() {
    let service = new ProductService()

    let { gtin, serial } = useParams();

    const [data, setData] = useState(null);
    const [certification, setCertification] = useState(false);
    const [form, setForm] = useState({});

    useEffect(() => {
        service.read(gtin, serial).then(function (result) {
            let res = result.getData();
            setData(res);
        })
    },[])


    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    const handleFormChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    function collapse(action) {
        return (
            <div className="d-flex flex-column align-items-center">
                <form className={'formNew'}>
                    <p>{CacheExtension.getDataFromCache(action)}</p>
                    <div className="form-group">
                        <input className="form-control" type='date' name="date" onChange={handleFormChange} />
                    </div>
                    <input type="text" placeholder={"* " + CacheExtension.getDataFromCache("Comment")} name="Comment" onChange={handleFormChange}/>
                    <div className="form-check">
                        <input
                            style={{width:"auto"}}
                            className="form-check-input"
                            type="checkbox"
                            name="certification"
                            id="certification"
                            checked={certification}
                            onChange={() => setCertification(!certification)}
                        />
                        <label className="form-check-label" htmlFor="certification">
                            * {CacheExtension.getDataFromCache("certification")}
                        </label>
                    </div>
                </form>

                <button className="w-50 button" onClick={() => service[action](data.key)}>{CacheExtension.getDataFromCache("Confirm")}</button>
            </div>
        )
    }
    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                {data &&
                    <>
                        <div className={'titleHead'} id="header">
                            <Link className="backLink" id="lostBack" to={ `/product/${gtin}/${serial}/service` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                            <h1 id="lostTitle">{CacheExtension.getDataFromCache("lost.title")}</h1>
                            <span id="productTokenText">{CacheExtension.getDataFromCache("lost.text")}</span>
                        </div>
                        <div id="content">
                            <ul className="container__link__service">
                                <li>
                                    <div className={'w-100 d-flex'}>
                                        <VolIcon id="productMenuIcon" width="1rem" />
                                        <CollapseComponent buttonClass={'collapseBtn'} buttonText={CacheExtension.getDataFromCache("lost.lost")} dom={collapse('lost')}/>
                                    </div>
                                </li>
                                <li>
                                    <div className={'w-100 d-flex'}>
                                        <VolIcon id="productMenuIcon" width="1rem" />
                                        <CollapseComponent buttonClass={'collapseBtn'} buttonText={CacheExtension.getDataFromCache("lost.stolen")} dom={collapse('stolen')}/>
                                    </div>
                                </li>
                                <li>
                                    <div className={'w-100 d-flex'}>
                                        <VolIcon id="productMenuIcon" width="1rem" />
                                        <CollapseComponent buttonClass={'collapseBtn'} buttonText={CacheExtension.getDataFromCache("lost.destroyed")} dom={collapse('destroyed')}/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </>
                }
            </m.div>
            <Footer selected="wallet"/>
        </>
    );
}