import { Link } from "react-router-dom";
import { HeaderLogo } from "../components/headerLogoComponent";
import '../../styles/login.css'
import '../../styles/register.css';
import {motion as m} from "framer-motion"
import LoginService from "../../StarterKit/Service/Bll/LoginService";
import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import Consts from "../Consts";
import SelectLanguageComponent from "../components/SelectLanguageComponent";

export default function LoginPage() {
    const [useProduct, setUseProduct] = useState('');
    const [product, setProduct] = useState([]);
    const navigate = useNavigate();
    var service = new LoginService();
    const [open, setOpen] = useState(false);
    const [openForget, setOpenForget] = useState(false);
    const [form, setForm] = useState(null);
    const [formRecover, setFormRecover] = useState(null);
    const [confirmation, setConfirmation] = useState('');
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseForget = () => {
        setOpenForget(false);
    };

    const handleOpenForget = () => {
        setOpenForget(true);
    };



    var url = JSON.parse(localStorage.getItem(Consts.GROUP_HOME));
    React.useEffect(() => {
        if (sessionStorage['isLogged'] !== undefined && sessionStorage['isLogged'] === 'yes'){
            navigate(url[sessionStorage['profile']].text)
        }
    },[])

    const handleFormChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    const handleFormRecoverChange = event => {
        setFormRecover({
            ...formRecover,
            [event.target.name]:event.target.value
        });
    }

    const submitConfirm = async (e) => {
        e.preventDefault();
        if(form && validate(form)) {
            console.log("blop")
            await service.confirm(form);
            setOpen(false);
        }
    };

    const submitRecover = async (e) => {
        e.preventDefault();
        if(formRecover) {
            await service.recoverClient(formRecover);
            setOpenForget(false);
        }
    };

    const validate = (data) => {
        const newErrors = {};
        if(!data.password || data.password !== confirmation) {
            newErrors.password = CacheExtension.getDataFromCache("PasswordDoesntMatch")
        }

        if(data.email) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const errors = [];
            if (!emailRegex.test(data.email)) {
                newErrors.mails = CacheExtension.getDataFromCache("InvalidEmail");
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidEmail")]
            newErrors.mails = error
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const  handleSubmit = async event => {
        event.preventDefault();
        const user = {
            username: document.getElementById('loginEmail').value,
            password: document.getElementById('loginPassword').value,
            service: "front"
        };
        var result = await service.login(user);
        if (result.getCode() === 0) {
            navigate('/')
        }
    }

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}} style={{overflowX:'hidden'}}>
                <div className={"w-100 d-flex justify-content-end containerLanguage"}>
                    <div className={"selectLanguage"}>
                        <SelectLanguageComponent/>
                    </div>
                </div>
                <div className={'titleHead container__loginpage '  + (useProduct ? 'container__information__register__product ' : '')} id="header">
                    <HeaderLogo />
                    <h1 id="loginTitle" className="absoluteTitle">{CacheExtension.getDataFromCache("login.title")}</h1>

                </div>

                <div style={{padding:'20px'}}>
                    <form  onSubmit={handleSubmit} >
                        <input type="email" name="email" placeholder={CacheExtension.getDataFromCache("login.email")} id="loginEmail" />
                        <div id="loginPasswordContainer">
                            <input type="password" name="password" placeholder={CacheExtension.getDataFromCache("login.password")} id="loginPassword"/>
                            <Link id="loginForgotten" onClick={handleOpenForget}>{CacheExtension.getDataFromCache("login.forgotten")}</Link>
                        </div>
                        <button id="loginButton" className="button" type="submit">{CacheExtension.getDataFromCache("login.login")}</button>
                        <Link className={'loginLink'} to={`/subscribe`}>{CacheExtension.getDataFromCache("login.signon")}</Link>
                        {/*<Link className={'loginLink'} to={`/new`}>{CacheExtension.getDataFromCache("RegisterProduct")}</Link>*/}
                        <Link className={'loginLink'} onClick={handleOpen}>{CacheExtension.getDataFromCache("Reinitialisation")}</Link>
                    </form>
                </div>
                {open && (
                    <div className="modal-container">
                        <div className="modal-overlay" onClick={handleClose}></div>
                        <div className="modal-dialog w-75" style={{maxWidth: "none"}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{CacheExtension.getDataFromCache('Reinitialisation')}</h5>
                                    <button type="button" className="close" onClick={handleClose}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={(e) => submitConfirm(e)}>
                                    <div className="modal-body">
                                        <div id="confirmUser_Form">
                                            <div id="confirmUser_Group_confirmToken_email" className="form-inline mb-2">
                                                <label htmlFor="confirmUser_Control_confirmToken_email"
                                                       className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                                       style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("email")}</label>
                                                <input id="confirmUser_Control_confirmToken_email" type="email" className="col-8 form-control sk-auto-form sk-form-email" name="email" onChange={handleFormChange} />
                                                {errors.mails && <div className="showError">{errors.mails}</div>}

                                            </div>
                                            <div id="confirmUser_Group_confirmToken_token" className="form-inline mb-2">
                                                <label htmlFor="confirmUser_Control_confirmToken_token"
                                                       className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                                       style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("ConfirmToken")}</label>
                                                <input id="confirmUser_Control_confirmToken_token" type="string"
                                                       className="col-8 form-control sk-auto-form sk-form-string" name="token"  onChange={handleFormChange}/>
                                            </div>
                                            <div id="confirmUser_Group_confirmToken_password"
                                                 className="form-inline mb-2">
                                                <label
                                                    htmlFor="confirmUser_Control_confirmToken_password"
                                                    className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                                    style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("Password")}</label>
                                                <input
                                                id="confirmUser_Control_confirmToken_password" type="password" name="password"
                                                className="col-8 form-control sk-auto-form sk-form-password"  onChange={handleFormChange}/>
                                                {errors.password && <div className="showError">{errors.password}</div>}

                                            </div>
                                            <div id="confirmUser_Group_confirmToken_confirmPassword" className="form-inline mb-2">
                                                <label
                                                    htmlFor="confirmUser_Control_confirmToken_confirmPassword"
                                                    className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                                    style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("ConfirmPassword")}</label>
                                                <input
                                                    id="confirmUser_Control_confirmToken_confirmPassword"
                                                    type="password"
                                                    name="confirmationPassword"
                                                    className="col-8 form-control sk-auto-form sk-form-password" onChange={(e)=> setConfirmation(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'modal-footer'}>
                                        <div className={'d-flex justify-content-center align-items-center'}>
                                            <div>
                                                <button type='submit' className={'btn btnBo submit'}>{CacheExtension.getDataFromCache("Validate")}</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                {openForget && (
                    <div className="modal-container">
                        <div className="modal-overlay" onClick={handleCloseForget}></div>
                        <div className="modal-dialog w-75" style={{maxWidth: "none"}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{CacheExtension.getDataFromCache('ForgottenTitle')}</h5>
                                    <button type="button" className="close" onClick={handleCloseForget}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={(e) => submitRecover(e)}>
                                    <div className="modal-body">
                                        <div id="confirmUser_Form">
                                            <div id="confirmUser_Group_confirmToken_email" className="form-inline mb-2">
                                                <label htmlFor="confirmUser_Control_confirmToken_email"
                                                       className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                                       style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("email")}</label>
                                                <input id="confirmUser_Control_confirmToken_email" type="email" className="col-8 form-control sk-auto-form sk-form-email" name="username" onChange={handleFormRecoverChange} />
                                                {errors.mails && <div className="showError">{errors.mails}</div>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className={'modal-footer'}>
                                        <div className={'d-flex justify-content-center align-items-center'}>
                                            <div>
                                                <button type='submit' className={'btn btnBo submit'}>{CacheExtension.getDataFromCache("Validate")}</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </m.div>
        </>
    );
}
