import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";

export default class ApiOwn extends BaseApiService {
    async ownAndCreate(data, product) {
        this.setPostMethod();
        this.setSecureByService();
        this.callNeedDataInBody();
        this.setBody(data);
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/ownAndCreate");
        return await this.callApi();
    }

    async ownAndUpdate(data, client) {
        this.setPutMethod();
        this.setSecureByService();
        this.callNeedDataInBody();
        this.setBody(data);
        this.setApiRoute(sessionStorage['language'] + "/products/" + client + "/ownAndUpdate");
        return await this.callApi();
    }

    async own(product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/own");
        return await this.callApi();
    }
}