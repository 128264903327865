import ApiWallet from "../Api/ApiWallet";
import ApiProfiles from "../../../StarterKit/Service/Api/ApiProfiles";
import HelperService from "../../../StarterKit/Service/Bll/HelperService";

export default class WalletService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiWallet();
        this.#helper = new HelperService();
    }

    async list() {
        let result = await this.#api.list();
        return result;
    }

    async import(file,product) {
        const result = await this.#api.import(file,product);
        return result;
    }
}