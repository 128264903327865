import {BaseApiService} from "../BaseApiService";
import ApiResult from "../../Model/ApiResult";

/*******************************************************************
 * Diji Starter Kit
 *
 * ApiLogin
 *******************************************************************/
export default class ApiSettings extends BaseApiService {
    /*******************************************************************
     * public function
     *******************************************************************/
    async getFromGroup(group) {
        this.setGetMethod();
        this.setSecureByService();
        this.setApiRoute((sessionStorage['language'] ?? 'FR') + "/settings/" + group);
        return await this.callApi();
    }

    async getSettings(group,page = 1) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/settings/" + group + "?page="+ page);
        return await this.callApi();
    }


    async addSetting(lang, group, setting) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(setting);
        this.setApiRoute(lang + "/settings/" + group);
        return await this.callApi();

    }

/********************/
    async updateSetting(lang, group, key, setting) {
        this.setPutMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(setting);
        this.setApiRoute(lang + "/settings/" + group + "/" + key);
        return await this.callApi();

    }


    async deleteSetting(lang,group,key) {
        this.setDeleteMethod();
        this.setSecureByUser();
        this.setApiRoute(lang + "/settings/" +group + "/" + key);
        return  await this.callApi();
    }
}
