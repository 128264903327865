import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState,} from "react";
import { HeaderLogo } from "../components/headerLogoComponent";
import '../../styles/subscribe.css';
import {motion as m} from "framer-motion"
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import InputComponent from "../components/InputComponent";
import Select from 'react-select';
import HelperService from "../Services/Bll/HelperService";
import ClientService from "../Services/Bll/ClientService";
import SelectLanguageComponent from "../components/SelectLanguageComponent";
import OwnService from "../Services/Bll/OwnService";
import LoginService from "../../StarterKit/Service/Bll/LoginService";
import ProductService from "../Services/Bll/ProductsService";

export default function QrLinkPage() {
    let { gtin, year, sku, serial, svid, signature  } = useParams()

    const ownService = new OwnService()
    const settingService = new SettingService()
    const clientService = new ClientService()
    const loginService = new LoginService();
    const productService = new ProductService();


    const [form, setForm] = useState({});
    const [mails, setMails] = useState(null);
    const [phones, setPhones] = useState(null);
    const [addresses, setAddresses] = useState(null);
    const [optinTypes, setOptinTypes] = useState([]);
    const [confirmation, setConfirmation] = useState('');
    const [settings, setSettings] = useState(null);
    const [errors, setErrors] = useState({});
    const [product, setProduct] = useState({});
    const [client, setClient] = useState(null);
    const [checkboxes, setCheckboxes] = useState({
        cgv: false,
        rgpd: false
    });


    const navigate = useNavigate()

    useEffect(() => {
        var helperService = new HelperService()
        if (sessionStorage.length === 0){
            helperService.setLocalStorage();
        }

        settingService.getFromGroup('optInTypes').then(function (result) {
            setSettings(Object.keys(result.getData()))
        })

        productService.read(gtin,serial).then((res)=> {
            let productRes = res.getData()
            setProduct(productRes)
            if(productRes.owner) {
                clientService.read(productRes.owner).then((r)=> {
                    let clientRes = r.getData()
                    setClient(clientRes)
                    setForm({
                        name : clientRes.name,
                        lastname : clientRes.lastName,
                        mails : clientRes.mails,
                        phones : clientRes.phones,
                        title : clientRes.title
                    });
                })
            }
        })


    }, [])
    const validate = (data) => {
        const newErrors = {};

        if (!data.name || data.name === "") {
            newErrors.name = CacheExtension.getDataFromCache("InvalidFirstname")
        }
        if (!data.lastname || data.lastname === "") {
            newErrors.lastname = CacheExtension.getDataFromCache("InvalidLastname")
        }
        if(!data.password || data.password !== confirmation) {
            newErrors.password = CacheExtension.getDataFromCache("PasswordDoesntMatch")
        }
        if (!data.age || data.age === "") {
            newErrors.age = CacheExtension.getDataFromCache("InvalidAge")
        }

        if(data.mails) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const errors = [];
            Object.keys(data.mails).forEach((email, index) => {
                if (!emailRegex.test(email)) {
                    errors[index] = CacheExtension.getDataFromCache("InvalidEmail");
                }
            });
            if(errors.length){
                newErrors.mails = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidEmail")]
            newErrors.mails = error
        }

        if(data.phones) {
            const phoneRegex = /^\d{10}$/;
            const errors = [];
            Object.keys(data.phones).forEach((phone, index) => {
                if (!phoneRegex.test(phone)) {
                    errors[index] = CacheExtension.getDataFromCache("InvalidPhone");
                }
            });
            if(errors.length){
                newErrors.phones = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidPhone")]
            newErrors.phones = error
        }

        if(data.addresses) {
            const errors = [];
            Object.keys(data.addresses).forEach((address, index) => {
                if (!data.addresses || data.addresses === "") {
                    errors[index] = CacheExtension.getDataFromCache("InvalidAddress");
                }
            });
            if(errors.length){
                newErrors.addresses = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidAddress")]
            newErrors.addresses = error
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const submitSubscribe = async (e) => {
        e.preventDefault();

        let optin = {};
        for (const i of optinTypes) {
            optin[i.value] = 1;
        }

        const updatedForm = {
            ...form,
            mails,
            phones,
            addresses,
            optIns : optin,
        };
        if(validate(updatedForm)) {
            if(client) {
                await ownService.ownAndUpdate(updatedForm, client);
            } else {
                await ownService.ownAndCreate(updatedForm, product.key);
            }

            const user = {
                username: Object.keys(updatedForm.mails)[0],
                password: updatedForm.password,
                service: "front"
            };
            var result = await loginService.login(user);
             if (result.getCode() === 0) {
                navigate('/')
            }
        }
    };

    const submitLogin = async (e) => {
        e.preventDefault();

        const user = {
            username: document.getElementById('loginEmail').value,
            password: document.getElementById('loginPassword').value,
            service: "front"
        };
        var result = await loginService.login(user);

        if (result.getCode() === 0) {
            await ownService.own(product.key);
            navigate('/')
        }
    };

    const handleFormChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    const handleChangeEmail = event => {
        setMails({
            [event.target.value] : 1
        });
    }

    const handleChangePhone = event => {
        setPhones({
            [event.target.value] : 1
        });
    }

    const onChangeSelect = (
        newValue,
        actionMeta
    ) => {
        switch (actionMeta.action) {
            case 'remove-value':
                newValue = optinTypes.filter(item => item !== actionMeta.removedValue)
            case 'clear':
                newValue = []
                break;
        }
        setOptinTypes(newValue);
    };

    const handleCheckboxChange = (event) => {
        setCheckboxes({
            ...checkboxes,
            [event.target.name]: event.target.checked
        });
    };

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                <div className={"w-100 d-flex justify-content-end containerLanguage"}>
                    <div className={"selectLanguage"}>
                        <SelectLanguageComponent/>
                    </div>
                </div>
                <div className={'titleHead container__loginpage'} id="header">
                    <HeaderLogo />
                    <div id="connectPicture"></div>
                </div>
                <div id="content">

                    {client && client.flagEnabled == 1 ?
                        <div style={{padding:'20px'}}>
                            <form  onSubmit={submitLogin} >
                                <input type="email" name="email" placeholder={CacheExtension.getDataFromCache("login.email")} id="loginEmail" />
                                <div id="loginPasswordContainer">
                                    <input type="password" name="password" placeholder={CacheExtension.getDataFromCache("login.password")} id="loginPassword"/>
                                    <span id="loginForgotten">{CacheExtension.getDataFromCache("login.forgotten")}</span>
                                </div>
                                <button id="loginButton" className="button" type="submit">{CacheExtension.getDataFromCache("login.login")}</button>
                            </form>
                        </div> :
                        <div>
                            <div id="subscribeIntro" className="textPR">{CacheExtension.getDataFromCache("subscribe.intro")}</div>
                            <form className={'formNew'} onSubmit={(e) => submitSubscribe(e)}>
                                <input type="text" value={form.name} placeholder={CacheExtension.getDataFromCache("Firstname")} name="name" id="subscribeFirstname" onChange={handleFormChange}/>
                                {errors.name && <div className="showError">{errors.name}</div>}
                                <input type="text" value={form.lastname} placeholder={CacheExtension.getDataFromCache("Lastname")} name="lastname" id="subscribeLastname" onChange={handleFormChange}/>
                                {errors.lastname && <div className="showError">{errors.lastname}</div>}
                                <input type="text" value={form.title} placeholder={CacheExtension.getDataFromCache("PersonTitle")} name="title" id="title" onChange={handleFormChange}/>
                                {errors.title && <div className="showError">{errors.title}</div>}
                                <input type="password" placeholder={CacheExtension.getDataFromCache("Password")} name="password" id="subscribePassword" onChange={handleFormChange}/>
                                <input type="password" placeholder={CacheExtension.getDataFromCache("ConfirmPassword")} name="confirmation" id="subscribeConfirmation" onChange={(e)=> setConfirmation(e.target.value)}/>
                                {errors.password && <div className="showError">{errors.password}</div>}
                                <input type="number" placeholder={CacheExtension.getDataFromCache("Age")} name="age" id="age" onChange={handleFormChange}/>
                                {errors.age && <div className="showError">{errors.age}</div>}
                                <input type="email" value={Object.keys(form.mails)[0]} placeholder={CacheExtension.getDataFromCache("email")} name="email" id="subscribeEmail" onChange={handleChangeEmail}/>
                                {errors.mails && <div className="showError">{errors.mails}</div>}
                                <input type="tel" value={Object.keys(form.phones)[0]} placeholder={CacheExtension.getDataFromCache("phone")} name="phone" id="subscribePhone" onChange={handleChangePhone}/>
                                {errors.phones && <div className="showError">{errors.phones}</div>}
                                {/*<InputComponent onInputChange={(values) => setMails(values)} defaultValues={form.mails} placeholder={'email'} type={'email'} errors={errors.mails}/>*/}
                                {/*<InputComponent onInputChange={(values) => setPhones(values)} defaultValues={form.phones} placeholder={'phone'} type={'tel'} errors={errors.phones}/>*/}
                                {/*<InputComponent onInputChange={(values) => setAddresses(values)} placeholder={'address'} type={'text'} errors={errors.addresses}/>*/}
                                <Select
                                    isMulti
                                    name="optin"
                                    className={'d-none'}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            neutral20: 'rgb(255, 158, 23)',
                                            neutral30: 'rgb(255, 158, 23)',
                                            primary25: 'rgba(255, 158, 23, 0.5)',
                                            danger: 'rgb(255, 158, 23)',
                                            dangerLight: 'rgba(255, 158, 23, 0.2)',
                                            primary: 'black',
                                        },
                                    })}
                                    placeholder={"notifications.select"}
                                    options={settings && settings.map((key, i) => ({value: key, label: CacheExtension.getDataFromCache(key)}))}
                                    onChange={onChangeSelect}
                                />
                                <div className="form-check">
                                    <input style={{width:"auto"}} className="form-check-input" type="checkbox" name="newsletter" id="newsletter"/>
                                    <label className="form-check-label" htmlFor="newsletter">
                                        {CacheExtension.getDataFromCache("newsletter")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        style={{width:"auto"}}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="cgv"
                                        id="cgv"
                                        checked={checkboxes.cgv}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="cgv">
                                        * {CacheExtension.getDataFromCache("cgv")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        style={{width:"auto"}}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="rgpd"
                                        id="rgpd"
                                        checked={checkboxes.rgpd}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="rgpd">
                                        * {CacheExtension.getDataFromCache("rgpd")}
                                    </label>
                                </div>
                                <button id="subscribeButton" disabled={!checkboxes.cgv || !checkboxes.rgpd} className="button" type="submit">{CacheExtension.getDataFromCache("subscribe.signon")}</button>
                                <Link className="buttonsubmit button" id="subscribeLogin" to={`/login`}>{CacheExtension.getDataFromCache("subscribe.login")}</Link>
                            </form>
                        </div>
                    }


                </div>
            </m.div>
        </>
    );
}


