export default class IntegerAutoform {
    #state
    constructor(parameter) {
        this.#state = {
            mandatory : false,
            modale : parameter.modale,
            parameter : parameter
        }
        if (this.#state.keyform) {
            this.#state.mandatory = true
        }
    };
    check() {
        var checkValue = true
        var returnTable = []
        var arrayInput = {}
        var errorCode = ""
        var className = "." +  this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData
        var input = document.querySelector(className)
        if (input.value === "" &&  this.#state.mandatory === true) {
            checkValue = false
        }
        if (input.value.trim() === "") {
            checkValue = false
        }
        if((parseFloat(input.value) == parseInt(input.value)) && !isNaN(input.value)){
        } else {
            checkValue = false
        }
        returnTable.push(checkValue,input.name,input.value,errorCode)
        return returnTable
    }

    render() {
        return(
            <div key={Math.random()} className={`banner ${ this.#state.modale == true ? "form-group" : ""}`}>
                <label>{'label-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData}</label>
                <input required={this.#state.mandatory === true ? 'required' : "false"} placeholder={'placeholder-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData} name={this.#state.parameter.parameterData} className={this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData + " form-control"} id={this.#state.parameter.parameteterClass} type="number"/>
            </div>
        )
    }
};

