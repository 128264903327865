import { Link, Navigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../res/back.svg';
import React, {useEffect, useState,} from "react";
import '../../styles/service.css';
import { ReactComponent as ChevronIcon } from '../../res/chevron.svg';
import { ReactComponent as EuroIcon } from '../../res/euro.svg';
import { ReactComponent as PlumeIcon } from '../../res/plume.svg';
import { ReactComponent as CessionIcon } from '../../res/cession.svg';
import { ReactComponent as GarantiIcon } from '../../res/garanti.svg';
import { ReactComponent as ReparationIcon } from '../../res/reparration.svg';
import { ReactComponent as SupportIcon } from '../../res/support.svg';
import { ReactComponent as VolIcon } from '../../res/vol.svg';
import {motion as m} from "framer-motion"
import {AnimatePresence} from "framer-motion"
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ProductService from "../Services/Bll/ProductsService";
import HelperService from "../Services/Bll/HelperService";
import {Footer} from "../components/footerComponent";

export default function ProductServicePage() {
    let service = new ProductService()

    let { gtin, serial } = useParams()

    const [data, setData] = useState(null);

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }

    useEffect(() => {
        service.read(gtin, serial).then(function (result) {
            let res = result.getData();
            setData(res);
        })

    },[])

    return (
        <>
            <AnimatePresence>
                <m.div key="container__animation"
                       className="container__animation"
                       animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
                       exit={{ marginLeft : '100vw', x: "inherit" }}
                       transition={{duration: 0.30, ease: "linear"}}>
                    {data &&
                        <>
                            <div className={'titleHead'} id="header">
                                <Link className="backLink" id="productServiceBack" to={ `/product/${gtin}/${serial}` }><BackIcon className="chevronBack"/>{ data.model.title }</Link>
                                <h1 id="productServiceTitle">{CacheExtension.getDataFromCache("productService.title")}</h1>
                            </div>
                            <div id="content">
                                <ul className="container__link__service">
                                    <li>
                                        <Link to={`/product/${gtin}/${serial}/service/warranty`}><GarantiIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("productService.extensionGaranti")}</Link>
                                        <ChevronIcon id="productMenuChevron" width="1rem" />
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Link to={`/product/${gtin}/${serial}/service/maintenance`}><ReparationIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("productService.entretienReparation")}</Link>*/}
                                    {/*    <ChevronIcon id="productMenuChevron" width="1rem" />*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <Link  to={`/product/${gtin}/${serial}/service/estimate`}><EuroIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("productService.estimationValeur")}</Link>*/}
                                    {/*    <ChevronIcon id="productMenuChevron" width="1rem" />*/}
                                    {/*</li>*/}
                                    <li>
                                        <Link to={`/product/${gtin}/${serial}/service/lost`}><VolIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("productService.perteVol")}</Link>
                                        <ChevronIcon id="productMenuChevron" width="1rem" />
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Link  to={`/product/${gtin}/${serial}/service/transfer`}><CessionIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("productService.cessionProduit")}</Link>*/}
                                    {/*    <ChevronIcon id="productMenuChevron" width="1rem" />*/}
                                    {/*</li>*/}
                                    <li>
                                        <Link to={`/product/${gtin}/${serial}/service/support`}><SupportIcon id="productMenuIcon" width="1rem" />{CacheExtension.getDataFromCache("productService.contactSupport")}</Link>
                                        <ChevronIcon id="productMenuChevron" width="1rem" />
                                    </li>
                                </ul>
                                <div className="container__img__montre">
                                    <img className="imageServiceFooter" src="/service.jpg" alt=""/>
                                </div>
                            </div>
                        </>
                    }
                </m.div>
                <Footer selected="wallet"/>
            </AnimatePresence>
        </>
    );
}