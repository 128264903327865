import Consts from "../../App/Consts";
import HelperService from "../Service/Bll/HelperService";

export default class CacheExtension {

    static getDataFromCache(key,group = "",defaultValue = null){
        var helper = new HelperService();
        if (group === ""){
            group = Consts.GROUP_LABELS
        }
        var result = helper.getTextSetting(group, key);
        if (result === ""){
            if (defaultValue){
                result = defaultValue
            }else {
                result = "?=>" + key
            }
        }
        result = result.replace("\\n\\r","<br />");
        return result;
    }
}

