import {Link, Navigate, useNavigate} from "react-router-dom";
import { Footer } from "../components/footerComponent";
import { ReactComponent as BackIcon } from '../../res/back.svg'
import '../../styles/updateCoordinates.css';
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ClientService from "../Services/Bll/ClientService";
import React, {useEffect, useState} from "react";
import InputComponent from "../components/InputComponent";
import HelperService from "../Services/Bll/HelperService";

export default function UpdateCoordinatesPage() {
    const service = new ClientService()

    const [data, setData] = useState(null);
    const [mails, setMails] = useState(null);
    const [phones, setPhones] = useState(null);
    const [addresses, setAddresses] = useState(null);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        service.read(sessionStorage['lastname']).then(function (result) {
            let res = result.getData();
            setData(res);
            setMails(res.mails)
            setPhones(res.phones)
            setAddresses(res.addresses)
        })
    },[])

    if (!HelperService.isLogged()) {
        return (  <Navigate to="/login" replace={true} />)
    }
    const validate = (data) => {
        const newErrors = {};

        if(data.mails) {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const errors = [];
            Object.keys(data.mails).forEach((email, index) => {
                if (!emailRegex.test(email)) {
                    errors[index] = CacheExtension.getDataFromCache("InvalidEmail");
                }
            });
            if(errors.length){
                newErrors.mails = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidEmail")]
            newErrors.mails = error
        }

        if(data.phones) {
            const phoneRegex = /^\d{10}$/;
            const errors = [];
            Object.keys(data.phones).forEach((phone, index) => {
                if (!phoneRegex.test(phone)) {
                    errors[index] = CacheExtension.getDataFromCache("InvalidPhone");
                }
            });
            if(errors.length){
                newErrors.phones = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidPhone")]
            newErrors.phones = error
        }

        if(data.addresses) {
            const errors = [];
            Object.keys(data.addresses).forEach((address, index) => {
                if (!data.addresses || data.addresses === "") {
                    errors[index] = CacheExtension.getDataFromCache("InvalidAddress");
                }
            });
            if(errors.length){
                newErrors.addresses = errors;
            }
        } else {
            let error = [CacheExtension.getDataFromCache("InvalidAddress")]
            newErrors.addresses = error
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const submit = async (e) => {
        e.preventDefault()

        const updatedForm = {
            mails,
            phones,
            addresses,
        };
        if(validate(updatedForm)) {
            await service.update(data.key, updatedForm)
            navigate('/profile')
        }
    }

  return (
    <>
        <m.div className="container__animation"
               initial={{width:'100vw' , marginLeft : '100vw' }}
               animate={{ marginLeft : '0', transitionEnd : { transform: "inherit" } }}
               exit={{ marginLeft : '100vw', x: "inherit" }}
               transition={{duration: 0.30, ease: "linear"}}>
            {data &&
                <>
                    <div className={'titleHead'} id="header">
                        <Link className="backLink" id="updateCoordinatesBack" to={`/profile`}><BackIcon className="chevronBack"/>{CacheExtension.getDataFromCache("updateCoordinates.back")}</Link>
                        <h1 id="productTokenTitle">{CacheExtension.getDataFromCache("updateCoordinates.title")}</h1>
                    </div>
                    <div id="content">
                        <form onSubmit={(e) => submit(e)}>
                            <span className="labelInput" id="updateCoordinateEmailLabel">{CacheExtension.getDataFromCache("register.email")}</span>
                            <InputComponent onInputChange={(values) => setMails(values)} type={'email'} errors={errors.mails} defaultValues={data.mails}/>
                            <span className="labelInput" id="updateCoordinatePhoneLabel">{CacheExtension.getDataFromCache("updateCoordinates.phone")}</span>
                            <InputComponent onInputChange={(values) => setPhones(values)} type={'phone'} errors={errors.phones} defaultValues={data.phones}/>
                            <span className="labelInput" id="updateCoordinateAddressesLabel">{CacheExtension.getDataFromCache("updateCoordinates.address")}</span>
                            <InputComponent onInputChange={(values) => setAddresses(values)} type={'address'} errors={errors.addresses} defaultValues={data.addresses}/>
                            <button id="updateCoordinatesButton"  className="button buttonsubmit" type="submit">{CacheExtension.getDataFromCache("updateCoordinates.update")}</button>
                        </form>
                    </div>
                </>
            }
        </m.div>
        <Footer selected="profile"/>
    </>
  );
}

