import {Link, useNavigate} from "react-router-dom";
import '../../styles/wallet.css';
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import { ReactComponent as VolIcon } from '../../res/vol.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ProductCard({ data }) {

    const navigate = useNavigate()

    return (
    <>
        <div className={'py-2 col-12 col-lg-3'} onClick={() => navigate(`/product/` + data.gtinSku + '/' + data.serial)}>
            <div className="productCard">
                <div className="productCardTitle p-2">
                    {data.title && <strong>{data.title}</strong>}
                    {data.subtitle && <div style={{fontSize:'10px'}}>{data.subtitle}</div>}
                </div>
                <div className={'productCardContent mb-1'}>
                    <div className={'productCardHeader p-2 d-flex justify-content-between w-100'}>
                        {data.state === 'inVerification' && (!data.invoice &&
                                <div className="invoiceMissing"><p>{CacheExtension.getDataFromCache("invoiceMissing")}</p></div>
                        )}
                        {data.state === 'inVerification' && (data.invoice && data.invoice != 'Refused' &&
                            <div className="invoiceVerif"><p>{CacheExtension.getDataFromCache("invoiceVerif")}</p></div>
                        )}
                        {data.state === 'inVerification' && (data.invoice == 'Refused' &&
                            <div className="invoiceVerif"><p>{CacheExtension.getDataFromCache("invoiceRefused")}</p></div>
                        )}
                    </div>
                    <div className={'productCardPicture'} style={{backgroundImage : `url(${data.image})`,mixBlendMode:'multiply' }}></div>
                </div>
            </div>

        </div>
    </>
    );
}








